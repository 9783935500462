import * as actionType from "../action/ActionTypes";

const initialState = {
  filterproperties: [],
  error: false,
  isLoading: false,
};

export const FilterProperties = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FILTER_PROPERTIES_SET_DATA:
      return {
        ...state,
        filterproperties: action.filterproperties,
        error: false,
        isLoading: false,
      };

    case actionType.FILTER_PROPERTIES_FAIL_DATA:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case actionType.FILTER_PROPERTIES_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    default:
      return state;
  }
};
