import React, { useEffect } from "react";
import { Container, Col, Card, CardBody, Row, CardFooter } from "reactstrap";
import * as actions from "../../../redux/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CircularProgres from "../../Loaders/CircularProgres";
import NoDatafound from "../../../assets/images/data.png";
import Stack from "@mui/material/Stack";
import { imageUrl } from "../../../shared/imageUrl";

const AgriculturalLand = (props) => {
  let data = {
    token: props.login?.login?.token,
  };

  useEffect(() => {
    props.onPropertiesGetData(data);
  }, []);

  return (
    <React.Fragment>
      <section id="img">
        <div class="container-fluid block">
          <h1>All Agricultural Lands</h1>
        </div>
      </section>
      <Container>
        <Row className="mt-3">
          {props.properties?.isLoading ? (
            <CircularProgres />
          ) : props.properties?.properties.length > 0 ? (
            props.properties?.properties?.map((home, index) => {
              if (home.property_type == "Agricultural Land")
              return (
                <Col md={4} key={index}>
                  <Card className="list-cards">
                    <img
                     src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                      // src={`https://uditsolutions.in/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                      width="100%"
                      height="200px"
                    />
                    <CardBody>
                      <ul className="list-group">
                        <li className="list-group-item">
                          <span className="font-weight-bold">
                            Property For :
                          </span>
                          &nbsp;
                          {home.posting_property_for}
                        </li>
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Property Type :
                          </span>
                          &nbsp;
                          {home.property_type}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Price :</span>
                          &nbsp; &#8377; {home.property_price}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Location :</span>
                          &nbsp; {home.prop_city},{home.prop_area}
                        </li>
                      </ul>
                    </CardBody>
                    <CardFooter className="justify-content-center d-flex">
                      <Link
                        to={`/property/${home.id}`}
                        className="btn btn-blue btn-sm text-white"
                      >
                        Read More
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Stack
              sx={{ width: "100%", textAlign: "center" }}
              spacing={2}
              className="mb-3"
            >
              <img src={NoDatafound} width="100%" height="100%" />
              {/* <Alert variant="outlined" severity="warning">
                No Data Found
              </Alert> */}
            </Stack>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    properties: state.properties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgriculturalLand);
