import React from "react";
import "./style.css";

const AboutUs = () => {
  return (
    <React.Fragment>
      <div className="about-landing-page">
        <div className="about-wrapper">
          <div className="d-flex flex-column justify-content-center text-center align-item-center h-100">
            <h5 className="display-4 animated zoomIn">About Us</h5>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mt-5 mb-5">
          <h3 className="font-bold">
            Welcome to True Homes India - Your Gateway to Seamless Property
            Solutions!
          </h3>
          <p>
            Since our inception in 2015, True Homes India has been a trusted
            name in the real estate industry, catering to the diverse property
            needs of our valued clients. As a premier portal for buying and
            selling properties, listing services, and more, we take immense
            pride in offering comprehensive real estate solutions across Mumbai,
            Pune, Nashik, Lonavala, and pan India, including property tie-ups in
            Dubai.
          </p>
          <p>
            At True Homes India, we are committed to providing tailored
            solutions that exceed expectations. Whether you're looking to buy a
            home, explore new real estate projects in Mumbai, or find property
            prices in Mumbai, we have you covered. Our extensive listings offer
            a variety of options, from affordable 1 BHK flat prices in Mumbai to
            luxurious 3 BHK flat prices.
          </p>
          <p>
            As part of our dedication to seamless property services, we also
            offer rental solutions, mortgage property assistance, and access to
            bank auction properties. We understand the importance of a
            well-designed living space, which is why our interior designing
            services ensure your home reflects your style and personality.
          </p>
          <p>
            In addition to property services, we extend our expertise to content
            writing, ensuring engaging and informative materials for your real
            estate projects. We leave no stone unturned when it comes to
            providing a holistic real estate experience.
          </p>
          <p>
            With a strong focus on transparency, professionalism, and
            efficiency, True Homes India aims to be your trusted partner in real
            estate. Whether you're searching for a property near you, ready to
            move homes, or exploring investment opportunities pan India, our
            team is dedicated to assisting you every step of the way.
          </p>
          <p>
            As we continue to broaden our horizons and explore new frontiers in
            the real estate landscape, we stay true to our commitment of
            providing the latest market insights and trends.
          </p>
          <p>
            Discover a world of possibilities with True Homes India - Your
            Trusted Partner in Real Estate! Contact us today to embark on your
            property journey.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
