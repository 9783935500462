import * as actionType from "./ActionTypes";
import axios from "../../axios";
import { baseUrl } from "../../shared/baseUrl";
import Swal from "sweetalert2";

export const singlepropertiesSetData = (singleproperties) => {
  return {
    type: actionType.SINGLE_PROPERTIES_SET_DATA,
    singleproperties: singleproperties,
  };
};

export const singlepropertiesFailData = (error) => {
  return {
    type: actionType.SINGLE_PROPERTIES_FAIL_DATA,
    error: error,
  };
};

export const singlepropertiesLoading = () => {
  return {
    type: actionType.SINGLE_PROPERTIES_LOADING,
  };
};
export const singlepropertiesGetData = (data) => {
  return (dispatch) => {
    dispatch(singlepropertiesLoading());
    axios
      .get(baseUrl + `/properties/${data.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        console.log(res.data, "res");
        dispatch(singlepropertiesSetData(res.data));
      })
      .catch((error) => dispatch(singlepropertiesFailData(error)));
  };
};
