import React, { useEffect, useState } from "react";
import { Container, Col, Card, CardBody, Row, CardFooter } from "reactstrap";
import * as actions from "../../redux/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { imageUrl } from "../../shared/imageUrl";
import TabPanel from "../home/filter-page/TabPanel";
import CircularProgres from "../Loaders/CircularProgres";
import NoDatafound from "../../assets/images/data.png";
import Stack from "@mui/material/Stack";

const Projects = (props) => {
  let data = {
    token: props.login?.login?.token,
  };

  useEffect(() => {
    props.onBuildingsGetData(data);
    props.onSinglebuildingsGetData(data);
  }, []);
  const [buildings, setBuildings] = useState(props.buildings?.buildings);
  console.log("buildings", buildings);
  const filterItem = (propType) => {
    const updateItems = props.buildings?.buildings.filter((curElm) => {
      return curElm.project_type === propType;
    });
    console.log("updateItems", updateItems);
    setBuildings(updateItems);
  };
  return (
    <React.Fragment>
      {/* <div className="container mt-5">
        <Formik
          initialValues={{
            firstname: "",
          }}
          //onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            //firstname: Yup.string().required("Firstname is required"),
          })}
        >
          {(formProps) => (
            <Form>
              <Row className="form-group">
                <Col md={10}>
                  <Row>
                    <Col md={3}>
                      <Label
                        htmlFor="categories"
                        className="font-weight-bold text-dark"
                      >
                        Locality
                      </Label>
                      <InputGroup>
                        <Field
                          component={CustomInput}
                          name="categories"
                          id="categories"
                          placeholder="Search in City"
                        />
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Label
                        htmlFor="city"
                        className="font-weight-bold text-dark"
                      >
                        Property Type
                      </Label>
                      <InputGroup>
                        <Field component={CustomSelect} name="city" id="city">
                          <option>Select Type</option>
                          <option>House</option>
                          <option>Plot</option>
                        </Field>
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Label
                        htmlFor="rooms"
                        className="font-weight-bold text-dark"
                      >
                        Rooms
                      </Label>
                      <InputGroup>
                        <Field component={CustomSelect} name="rooms" id="rooms">
                          <option>Select Type</option>
                          <option>1 RK</option>
                          <option>1 BHK</option>
                          <option>2 BHK</option>
                          <option>3 BHK</option>
                          <option>4 BHK</option>
                        </Field>
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Label
                        htmlFor="price-range"
                        className="font-weight-bold text-dark"
                      >
                        Price Range
                      </Label>
                      <InputGroup>
                        <SliderProton
                        //   value={selectedPrice}
                        //   changePrice={changePrice}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={2}>
                  <Row>
                    <Col md={12}>
                      <Button className="btn-search mt-4 p-2">
                        <i
                          className="fa fa-search text-light"
                          aria-hidden="true"
                        />
                        &nbsp;Search
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div> */}
      <div className="container-fluid" style={{ padding: "0px" }}>
        <TabPanel />
      </div>
      <Container className="mt-4">
        <Row>
          <Col md={3}></Col>
          <Col md={6} className="text-center">
            <div>
              <button
                className="btn btn-sm btn-blue text-white"
                onClick={() => filterItem("Residential")}
              >
                Residential
              </button>
              <button
                className="btn btn-sm btn-blue text-white"
                onClick={() => filterItem("Commercial")}
              >
                Commercial
              </button>
            </div>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-3 mb-3">
          {props.buildings?.isLoading ? (
            <CircularProgres />
          ) : buildings.length > 0 ? (
            buildings?.map((home, index) => {
              return (
                <Col md={4} key={index}>
                  <Card className="list-cards">
                    <img
                      src={`${imageUrl}/BuildingPhotos/${home.images[0]?.file_name}`}
                      // src={`http://www.omra.in/wp-data-1/TrueHomesBackend/storage/app/public/BuildingPhotos/${home.images[0]?.file_name}`}
                      width="100%"
                      height="200px"
                    />
                    <CardBody>
                      <ul className="list-group">
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Project Type :
                          </span>
                          &nbsp;
                          {home.project_type}
                        </li>
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Property Type :
                          </span>
                          &nbsp;
                          {home.property_type}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">
                            Price Range :
                          </span>
                          &nbsp; &#8377; {home.price_range}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Location :</span>
                          &nbsp; {home.landmark.substring(0, 10) + "..."}{" "}
                          {home.address.substring(0, 10) + "..."}
                        </li>
                      </ul>
                    </CardBody>
                    <CardFooter className="justify-content-center d-flex">
                      <Link
                        to={`/project/${home.id}`}
                        className="btn btn-blue btn-sm text-white"
                      >
                        Read More
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Stack
              sx={{ width: "100%", textAlign: "center" }}
              spacing={2}
              className="mb-3"
            >
              <img src={NoDatafound} width="100%" height="100%" />
            </Stack>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    buildings: state.buildings,
    singlebuildings: state.singlebuildings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSinglebuildingsGetData: (data) =>
      dispatch(actions.singlebuildingsGetData(data)),
    onBuildingsGetData: (data) => dispatch(actions.buildingsGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Projects);
