import * as actionType from "./ActionTypes";
import axios from "../../axios";
import { baseUrl } from "../../shared/baseUrl";
import Swal from "sweetalert2";

export const cfilterSetData = (cfilter) => {
  return {
    type: actionType.FILTER_COMMERTIAL_PROPERTIES_SET_DATA,
    cfilter: cfilter,
  };
};

export const cfilterFailData = (error) => {
  return {
    type: actionType.FILTER_COMMERTIAL_PROPERTIES_FAIL_DATA,
    error: error,
  };
};

export const cfilterLoading = () => {
  return {
    type: actionType.FILTER_COMMERTIAL_PROPERTIES_LOADING,
  };
};

export const postcFilterData = (data, property, toggle, setSubmitting) => {
  return (dispatch) => {
    console.log("property ", property);
    dispatch(cfilterLoading());
    axios
      .post(baseUrl + "/filtered-commercial-properties", property, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        console.log("cfilterSetData", res.data);
        dispatch(cfilterSetData(res.data));
        // dispatch(propertiesGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Search",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(cfilterFailData(error));
      });
  };
};
