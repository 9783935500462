import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import CircularProgres from "../Loaders/CircularProgres";
import NoDatafound from "../../assets/images/data.png";
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  InputGroup,
  Button,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { imageUrl } from "../../shared/imageUrl";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  thumb: {
    color: "#0073e1",
  },
  rail: {
    color: `rgba(0, 0, 0, 0.26)`,
  },
  track: {
    color: "#0073e1",
  },
});
const SearchResults = (props) => {
  // console.log("props", props);
  const classes = useStyles();
  const [properties, setProperties] = useState(
    props.filterproperties?.filterproperties
  );
  // console.log("properties", properties);

  // price range
  const [selectedPrice, setSelectedPrice] = useState([0, 100000]);
  const [resultsFound, setResultsFound] = useState(true);
  // Price Filter
  // console.log("selectedPrice", selectedPrice);
  const handleChangePrice = (event, value) => {
    setSelectedPrice(value);
  };
  const minPrice = selectedPrice[0];
  const maxPrice = selectedPrice[1];
  const applyFilters = () => {
    let updatedList = props.filterproperties?.filterproperties;
    // Price Filter
    const minPrice = selectedPrice[0];
    const maxPrice = selectedPrice[1];

    updatedList = updatedList.filter(
      (item) =>
        item.property_price >= minPrice && item.property_price <= maxPrice
    );

    setProperties(updatedList);

    !updatedList.length ? setResultsFound(false) : setResultsFound(true);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedPrice]);
  return (
    <React.Fragment>
      <Container>
        {/* <Row className="mt-3">
          <Col md={4}>
            <Label
              htmlFor="property_price"
              className="font-weight-bold text-dark"
            >
              Price Range : &#8377;&nbsp;{minPrice} to &#8377;&nbsp;{maxPrice}
            </Label>
            <Slider
              value={selectedPrice}
              onChange={handleChangePrice}
              valueLabelDisplay="auto"
              step={10000}
              min={0}
              max={100000}
              classes={{
                thumb: classes.thumb,
                rail: classes.rail,
                track: classes.track,
              }}
            />
          </Col>
        </Row> */}
        <Row className="mt-3">
          {props.filterproperties?.isLoading ? (
            <CircularProgres />
          ) : props.filterproperties?.filterproperties.length > 0 ? (
            props.filterproperties?.filterproperties?.map((home, index) => {
              return (
                <Col md={4} key={index}>
                  <Card className="list-cards">
                    <img
                      src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                      // src={`https://uditsolutions.in/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                      width="100%"
                      height="200px"
                    />
                    <CardBody>
                      <ul className="list-group">
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Property For :
                          </span>
                          &nbsp;
                          {home.posting_property_for}
                        </li>
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Property Type :
                          </span>
                          &nbsp;
                          {home.property_type}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Price :</span>
                          &nbsp; &#8377; {home.property_price}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Location :</span>
                          &nbsp; {home.prop_city},{home.prop_area}
                        </li>
                      </ul>
                    </CardBody>
                    <CardFooter className="justify-content-center d-flex">
                      <Link
                        to={`/property/${home.id}`}
                        className="btn btn-blue btn-sm text-white"
                      >
                        Read More
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Stack
              sx={{ width: "100%", textAlign: "center" }}
              spacing={2}
              className="mb-3"
            >
              <img src={NoDatafound} width="100%" height="100%" />
            </Stack>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    filterproperties: state.filterproperties,
  };
};

export default connect(mapStateToProps)(SearchResults);
