import * as ActionTypes from "../action/ActionTypes";

export const Register = (
  state = { isLoading: false, errMess: null, register: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_REGISTER:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        register: action.register,
      };

    case ActionTypes.REGISTER_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case ActionTypes.REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.errMess,
        register: [],
      };

    default:
      return state;
  }
};
