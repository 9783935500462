import React from "react";
import logo from "../../assets/images/true_homes_logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useSelector, connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import * as actions from "../../redux/action";

const NavigationBar = (props) => {
  const { login } = useSelector((state) => state.login);
  // console.log("login", login);
  function handleLogOut() {
    props.removeLogin();
  }
  return (
    <Navbar bg="light" expand="lg" className="navbar">
      <Container>
        <Navbar.Brand as={NavLink} to={"/"}>
          <img src={logo} width="225" height="65" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to={"/"} className="font-weight-bold">
              Home
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to={"/property-listing"}
              className="font-weight-bold"
            >
              Properties
            </Nav.Link>
            <Nav.Link as={NavLink} to={"/project"} className="font-weight-bold">
              Projects
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to={"/about-us"}
              className="font-weight-bold"
            >
              About US
            </Nav.Link>
            {login.length != 0 ? (
              <Nav.Link
                as={NavLink}
                to={"/my-properties"}
                className="font-weight-bold"
              >
                My Properties
              </Nav.Link>
            ) : null}
          </Nav>
          <Nav className="ml-auto">
            {login.length == 0 ? (
              <Nav.Link as={NavLink} to={"/login"} className="font-weight-bold">
                <FaUserCircle />
                &nbsp; Login
              </Nav.Link>
            ) : (
              <Nav.Link
                as={NavLink}
                to={"/login"}
                onClick={() => handleLogOut()}
                className="font-weight-bold"
              >
                <BiLogOut />
                &nbsp; Logout
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeLogin: (data) => dispatch(actions.removeLogin(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
