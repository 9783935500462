import * as actionType from "./ActionTypes";
import axios from "../../axios";
import { baseUrl } from "../../shared/baseUrl";
import Swal from "sweetalert2";

export const singlebuildingsSetData = (singlebuildings) => {
  return {
    type: actionType.SINGLE_BUILDINGS_SET_DATA,
    singlebuildings: singlebuildings,
  };
};

export const singlebuildingsFailData = (error) => {
  return {
    type: actionType.SINGLE_BUILDINGS_FAIL_DATA,
    error: error,
  };
};

export const singlebuildingsLoading = () => {
  return {
    type: actionType.SINGLE_BUILDINGS_LOADING,
  };
};
export const singlebuildingsGetData = (data) => {
  return (dispatch) => {
    dispatch(singlebuildingsLoading());
    axios
      .get(baseUrl + `/buildings/${data.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        console.log(res.data, "res");
        dispatch(singlebuildingsSetData(res.data));
      })
      .catch((error) => dispatch(singlebuildingsFailData(error)));
  };
};
