import * as actionType from "./ActionTypes";
import axios from "../../axios";
import { baseUrl } from "../../shared/baseUrl";
import Swal from "sweetalert2";

export const filterpropertiesSetData = (filterproperties) => {
  return {
    type: actionType.FILTER_PROPERTIES_SET_DATA,
    filterproperties: filterproperties,
  };
};

export const filterpropertiesFailData = (error) => {
  return {
    type: actionType.FILTER_PROPERTIES_FAIL_DATA,
    error: error,
  };
};

export const filterpropertiesLoading = () => {
  return {
    type: actionType.FILTER_PROPERTIES_LOADING,
  };
};

export const postFilterPropertiesData = (
  data,
  property,
  toggle,
  setSubmitting
) => {
  return (dispatch) => {
    console.log("property", property);
    dispatch(filterpropertiesLoading());
    axios
      .post(baseUrl + "/filtered-properties", property, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        console.log("res.data filter", res.data);
        dispatch(filterpropertiesSetData(res.data));
        // dispatch(propertiesGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Search",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(filterpropertiesFailData(error));
      });
  };
};
