import React from "react";
import { Container } from "reactstrap";
import List from "./all-list/List";
import FeaturedProperties from "./FeaturedProperties/FeaturedProperties";
import ImagesSection from "./image-section/ImagesSection";
import PropertyArea from "./property-area/PropertyArea";
import NavTabs from "./nav-tabs/NavTabs";
import LogoSection from "./logo-section/LogoSection";
import Testimonials from "./testomonials/Testimonials";
import Agents from "./our-agents/Agents";
import Contact from "./contact-us/Contact";
//import LabTabs from "./filter-page/LabTabs";
import TabPanel from "./filter-page/TabPanel";

const Home = () => {
  return (
    <React.Fragment>
      {/* <LabTabs /> */}
      <TabPanel />
      <Container className="mt-3">
        <List />
      </Container>
      <FeaturedProperties />
      {/* <ImagesSection /> */}
      {/* <PropertyArea /> */}
      {/* <NavTabs /> */}
      {/* <LogoSection /> */}
      <Testimonials />
      {/* <Agents /> */}
      <Contact />
    </React.Fragment>
  );
};

export default Home;
