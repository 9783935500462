import React from "react";
import "./App.css";
import { configureStore } from "./redux/configureStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import NavigationBar from "./components/navbar/NavigationBar";
import {
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/home/Home";
import AboutUs from "./components/home/about/AboutUs";
import Property from "./components/property/Property";
import PropertyListing from "./components/property-listing/PropertyListing";
import Footer from "./components/footer/Footer";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import MyProperties from "./components/my-properties/MyProperties";
import Projects from "./components/projects/Projects";
import ViewprojectDetails from "./components/projects/ViewprojectDetails";
import FlatProperties from "./components/property-listing/categories-properties/FlatProperties";
import House from "./components/property-listing/categories-properties/House";
import FarmHouse from "./components/property-listing/categories-properties/FarmHouse";
import Showroom from "./components/property-listing/categories-properties/Showroom";
import Plot from "./components/property-listing/categories-properties/Plot";
import Shop from "./components/property-listing/categories-properties/Shop";
import OfficeSpace from "./components/property-listing/categories-properties/OfficeSpace";
import AgriculturalLand from "./components/property-listing/categories-properties/AgriculturalLand";
import SearchResults from "./components/property-listing/SearchResults";
import CommertialSearch from "./components/property-listing/CommertialSearch";
import ForgotPassword from "./components/auth/ForgotPassword";
import UpdatePassword from "./components/auth/UpdatePassword";

const { persistor, store } = configureStore();
function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <NavigationBar />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/property-listing" element={<PropertyListing />} />
            <Route path="/my-properties" element={<MyProperties />} />
            <Route path="/property/:id" element={<Property />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/project/:id" element={<ViewprojectDetails />} />
            <Route path="/about-us" element={<AboutUs />} />

            {/* ---------categories------- */}
            <Route path="/all-flat" element={<FlatProperties />} />
            <Route path="/all-house" element={<House />} />
            <Route path="/all-farm-house" element={<FarmHouse />} />
            <Route path="/all-showroom" element={<Showroom />} />
            <Route path="/all-plot" element={<Plot />} />
            <Route path="/all-shop" element={<Shop />} />
            <Route path="/office-space" element={<OfficeSpace />} />
            <Route path="/agricultural-land" element={<AgriculturalLand />} />
            {/* ---------categories------- */}
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/search-commertial" element={<CommertialSearch />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<UpdatePassword/>}/>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </PersistGate>  
      </Provider>
    </React.Fragment>
  );
}
export default App;
