import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Col, InputGroup, Label, Row } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import CustomInput from "../custom/CustomInput";
import * as actions from "../../redux/action";
import { connect } from "react-redux";
import * as Yup from "yup";
//import CircularProgres from "../Loaders/CircularProgres";
import LinerLoader from "../Loaders/LinerLoader";

const Register = (props) => {
  const navigate = useNavigate();

  const toggle = () => {
    navigate("/login");
  };
  const handleSubmit = (values, { setSubmitting }) => {
    let register = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      password_confirmation: values.password_confirmation,
      is_customer: values.is_customer,
      is_paid_member: values.is_paid_member,
    };
    console.log("values register", values);
    setSubmitting(false);
    props.postRegister(register, toggle);
    return;
  };
  return (
    <React.Fragment>
      <div>
        <div className="auth-wrapper align-items-center">
          <div className="container" style={{ paddingTop: 35 }}>
            <div className="no-gutters justify-content-center row">
              <div className="bg-light col-md-6 col-lg-4 register-card">
                <div className="p-4">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      password: "",
                      password_confirmation: "",
                      is_customer: 1,
                      is_paid_member: 1,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Enter Your Name"),
                      email: Yup.string()
                        .required("Enter Your Email")
                        .email("Invalid Email address"),
                      password: Yup.string()
                        .required("No password provided.")
                        .min(
                          6,
                          "Password is too short - should be 6 chars minimum."
                        ),
                      password_confirmation: Yup.string().when("password", {
                        is: (val) => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                          [Yup.ref("password")],
                          "Both password need to be the same"
                        ),
                      }),
                    })}
                  >
                    {(formProps) => (
                      <Form className="mt-3">
                        <Row className="form-group">
                          <Col md={12}>
                            <Label htmlFor="name" className="font-weight-bold">
                              Name
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Enter Your Name"
                                className={
                                  "form-control" +
                                  (formProps.errors.name &&
                                  formProps.touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={12}>
                            <Label
                              htmlFor="contact"
                              className="font-weight-bold"
                            >
                              Mobile No
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="number"
                                name="phone"
                                id="phone"
                                placeholder="Enter Your Phone"
                                className={
                                  "form-control" +
                                  (formProps.errors.phone &&
                                  formProps.touched.phone
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={12}>
                            <Label htmlFor="email" className="font-weight-bold">
                              Email
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="text"
                                name="email"
                                id="email"
                                placeholder="yourname@company.com"
                                className={
                                  "form-control" +
                                  (formProps.errors.email &&
                                  formProps.touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col md={12}>
                            <Label
                              htmlFor="password"
                              className="font-weight-bold"
                            >
                              Password
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="* * * * * *"
                                className={
                                  "form-control" +
                                  (formProps.errors.password &&
                                  formProps.touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={12}>
                            <Label
                              htmlFor="password_confirmation"
                              className="font-weight-bold"
                            >
                              Confirm Password
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="password"
                                name="password_confirmation"
                                id="password_confirmation"
                                placeholder="* * * * * *"
                                className={
                                  "form-control" +
                                  (formProps.errors.password_confirmation &&
                                  formProps.touched.password_confirmation
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password_confirmation"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <div className="mt-3 mb-3 row">
                          <div className="col-6">
                            <Button
                              type="submit"
                              disabled={formProps.isSubmitting}
                              className="bg-blue"
                              size="md"
                              block
                            >
                              Sign Up
                            </Button>
                          </div>
                          <div className="col-6">
                            <Button
                              type="reset"
                              disabled={formProps.isSubmitting}
                              color="danger"
                              size="md"
                              block
                            >
                              RESET
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div>{props.register?.isLoading && <LinerLoader />}</div>
                  <div className="mt-3">
                    Already have an account ?{" "}
                    <Link to="/login" className="signup-link">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postRegister: (register, toggle) => {
    dispatch(actions.postRegister(register, toggle));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
