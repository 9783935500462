import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card, CardBody, Container } from "reactstrap";
import BuyFilters from "./BuyFilters";
import RentFilters from "./RentFilters";
import CommertialFilters from "./CommertialFilters";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="landing-page">
      <div className="wrapper-div" style={{ paddingTop: "90px" }}>
        <Container className="bg-white">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  centered
                >
                  <Tab label="Buy" value="1" />
                  <Tab label="Rent" value="2" />
                  <Tab label="Commercial" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <BuyFilters />
              </TabPanel>
              <TabPanel value="2">
                <RentFilters />
              </TabPanel>
              <TabPanel value="3">
                <CommertialFilters />
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </div>
    </div>
  );
}
