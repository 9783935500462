import * as ActionTypes from "./ActionTypes";
import Swal from "sweetalert2";
import axios from "../../axios";

export const forgotSetData = (forgot) => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_SET_DATA,
    forgot: forgot,
  };
};
export const forgotFailData = (error) => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_FAIL_DATA,
    error: error,
  };
};
//loading
export const forgotLoading = () => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_LOADING,
  };
};

//Add new code
export const forgotPassword = (data, toggle) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  }; 
  return (dispatch) => {
    axios
      .post("/forget-password", data, config)
      .then((res) => {
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Send Update Link",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(forgotSetData(res.data));
        if (toggle) {
          toggle();
        }
      })
      .catch((error) => console.log("api error", error));
  };
};
