import React, { useState, useEffect } from "react";
import "../property/propert.css";
import CircularProgres from "../Loaders/CircularProgres";
import { Carousel } from "react-bootstrap";
import {
  Card,
  Col,
  Container,
  Row,
  InputGroup,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";
import { connect } from "react-redux";
import * as actions from "../../redux/action";
import ReactPlayer from "react-player";
import { imageUrl } from "../../shared/imageUrl";
import { Stack } from "@mui/material";
// import Rentals1 from "./Rentals1";
// import Sales1 from "./Sales1";
// import Invest1 from "./Invest1";

const ViewprojectDetails = (props) => {
  console.log("props view project", props);
  const { id } = useParams();
  const [index, setIndex] = useState(0);
  let data = {
    token: props.login?.login?.token,
    id: id,
  };

  useEffect(() => {
    props.onSinglebuildingsGetData(data);
  }, []);

  let [homes, setHomes] = useState(props.singlebuildings?.singlebuildings);
  console.log("homes", homes);

  let [loading, setLoading] = useState(false);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      {props.singlebuildings.isLoading ? (
        <CircularProgres />
      ) : (
        <div id="all-details">
          <section id="slider">
            <Carousel activeIndex={index} onSelect={handleSelect} fade>
              {props.singlebuildings?.singlebuildings?.images?.length > 0 &&
                props.singlebuildings?.singlebuildings?.images?.map(
                  (img, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={`${imageUrl}/BuildingPhotos/${img.file_name}`}
                          alt="No Images Available"
                        />
                      </Carousel.Item>
                    );
                  }
                )}
            </Carousel>
          </section>
          <section className="description">
            <div className="container">
              <Link to="/project" className="btn btn-blue btn-sm text-white">
                Go Back
              </Link>
            </div>
            <Container className="mt-5">
              <div>
                <h2 className="font-weight-bold">
                  Property Type :- &nbsp;
                  <span className="text-blue">
                    {props.singlebuildings?.singlebuildings.property_type}
                  </span>
                </h2>
              </div>
              <div>
                <Row>
                  <Col md={8}>
                    <Card className="all-info-cards">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Address</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <ul className="list-group">
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Address :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings
                                  ?.address ?? "N/A"}
                              </li>

                              <li className="list-group-item">
                                <span className="font-weight-bold">
                                  Landmark :
                                </span>
                                &nbsp;{" "}
                                {props.singlebuildings?.singlebuildings
                                  ?.landmark ?? "N/A"}
                              </li>
                              <li className="list-group-item">
                                <span className="font-weight-bold">
                                  Pincode :
                                </span>
                                &nbsp;{" "}
                                {props.singlebuildings?.singlebuildings
                                  ?.pincode ?? "N/A"}
                              </li>
                              <li className="list-group-item">
                                <span className="font-weight-bold">
                                  Contact :
                                </span>
                                &nbsp;{" "}
                                {props.singlebuildings?.singlebuildings
                                  ?.contact ?? "N/A"}
                              </li>
                            </ul>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card>

                    <Card className="all-info-cards mt-4">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Details</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <ul className="list-group">
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Project Id :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings?.id ??
                                  "N/A"}
                              </li>
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Project Name :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings?.name ??
                                  "N/A"}
                              </li>
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Price Range :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings
                                  ?.price_range ?? "N/A"}
                              </li>
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Property Type :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings
                                  ?.property_type ?? "N/A"}
                              </li>
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Project Type :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings
                                  ?.project_type ?? "N/A"}
                              </li>
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Total Towers :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings
                                  ?.total_towers ?? "N/A"}
                              </li>
                              <li className="list-group-item ">
                                <span className="font-weight-bold">
                                  Total Units :
                                </span>
                                &nbsp;
                                {props.singlebuildings?.singlebuildings
                                  ?.total_units ?? "N/A"}
                              </li>
                            </ul>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card>
                    <Card className="all-info-cards mt-4">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Highlights</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="row">
                              {props.singlebuildings?.singlebuildings
                                ?.highlights?.length > 0 &&
                                props.singlebuildings?.singlebuildings?.highlights?.map(
                                  (el, index) => {
                                    return (
                                      <div className="col-md-12" key="index">
                                        <p>
                                          <BsArrowRightCircle className="text-info" />
                                          &nbsp;{el?.highlight}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card>
                    <Card className="all-info-cards mt-4">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Advantages</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="row">
                              {props.singlebuildings?.singlebuildings
                                ?.advantages?.length > 0 &&
                                props.singlebuildings?.singlebuildings?.advantages?.map(
                                  (el, index) => {
                                    return (
                                      <div className="col-md-12" key="index">
                                        <p>
                                          <BsArrowRightCircle className="text-info" />
                                          &nbsp;{el.advantages}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card>
                    <Card className="all-info-cards mt-4">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Amenities</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="row" key={index}>
                              {props.singlebuildings?.singlebuildings
                                ?.amenity_details?.length > 0 &&
                                props.singlebuildings?.singlebuildings?.amenity_details?.map(
                                  (el, index) => {
                                    return (
                                      <div className="col-md-4">
                                        <p>
                                          <img
                                            src={`${imageUrl}/AmenityImages/${el.amenity?.image}`}
                                            width={"40px"}
                                            height={"40px"}
                                          />
                                          &nbsp;{el.amenity?.name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card>
                    <Card className="all-info-cards mt-4">
                      <h6>Description</h6>
                      <p className="mt-3">
                        {props.singlebuildings?.singlebuildings?.about}
                      </p>
                    </Card>

                    <Card className="all-info-cards mt-4">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Video</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div>
                              <ReactPlayer
                                controls
                                width="100%"
                                url={
                                  props.singlebuildings?.singlebuildings
                                    ?.video_url
                                }
                              />
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card>
                    {/* <Card className="all-info-cards mt-4">
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <b>Map</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15007.205095833027!2d86.08236547160838!3d19.89061158601113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19ed591ff3c6b5%3A0x5ae97a04d50af18b!2sKonark%2C%20Odisha%20752111!5e0!3m2!1sen!2sin!4v1662641208961!5m2!1sen!2sin"
                              width="100%"
                              height="450"
                              loading="lazy"
                            ></iframe>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Card> */}
                  </Col>
                  <Col md={4}>
                    <Card className="all-info-cards">
                      <h6>Our Listings</h6>
                      <div className="mt-3 listing-list">
                        <ul>
                          <li>
                            <Link to="/all-flat" className="listing-list-link">
                              Flat
                            </Link>
                          </li>
                          <hr />
                          <li>
                            <Link to="/all-house" className="listing-list-link">
                              House
                            </Link>
                            {/* <span>(8)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link
                              to="/all-farm-house"
                              className="listing-list-link"
                            >
                              Farm House
                            </Link>
                            {/* <span>(5)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link
                              to="/all-showroom"
                              className="listing-list-link"
                            >
                              Showroom
                            </Link>
                            {/* <span>(1)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link to="/all-plot" className="listing-list-link">
                              Plot
                            </Link>
                            {/* <span>(1)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link to="/all-shop" className="listing-list-link">
                              Shop
                            </Link>
                            {/* <span>(2)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link
                              to="/office-space"
                              className="listing-list-link"
                            >
                              Office Space
                            </Link>
                            {/* <span>(4)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link
                              to="/agricultural-land"
                              className="listing-list-link"
                            >
                              Agricultural Land
                            </Link>
                            {/* <span>(4)</span> */}
                          </li>
                          <hr />
                          <li>
                            <Link
                              to="/property-listing"
                              className="listing-list-link"
                            >
                              Other
                            </Link>
                          </li>
                          <hr />
                        </ul>
                      </div>
                    </Card>
                    <Card className="all-info-cards mt-4">
                      <h6>Verified Projects</h6>

                      <Row className="">
                        {props.buildings?.isLoading ? (
                          <CircularProgres />
                        ) : props.buildings?.buildings?.length > 0 ? (
                          props.buildings?.buildings
                            ?.filter((item) => item.is_feature == "1")
                            ?.map((home, index) => {
                              return (
                                <Col md={12} key={index}>
                                  <Card className="list-cards">
                                    <div className="row ">
                                      <div
                                        class="col-md-6"
                                        style={{ paddingRight: "0px" }}
                                      >
                                        <Link
                                          to={`/property/${home.id}`}
                                          style={{
                                            textDecoration: "none",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <img
                                            src={`${imageUrl}/BuildingPhotos/${home.images[0]?.file_name}`}
                                            width="100%"
                                            height="110px"
                                            className="card-img"
                                          />
                                        </Link>
                                      </div>
                                      <div
                                        class="col-md-6"
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <div
                                          className="card-body"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          <p
                                            className="text-dark"
                                            style={{ margin: "0" }}
                                          >
                                            For :{home.property_type}
                                          </p>
                                          <p
                                            className="text-warning font-weight-bold"
                                            style={{ margin: "0" }}
                                          >
                                            from :&nbsp; &#8377;{" "}
                                            {home.price_range}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              );
                            })
                        ) : (
                          <Stack
                            sx={{ width: "100%", textAlign: "center" }}
                            spacing={2}
                            className="mb-3 p-4"
                          >
                            No Data Found
                          </Stack>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    singlebuildings: state.singlebuildings,
    buildings: state.buildings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSinglebuildingsGetData: (data) =>
      dispatch(actions.singlebuildingsGetData(data)),
    onBuildingsGetData: (data) => dispatch(actions.buildingsGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewprojectDetails);
