import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./List.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  CardFooter,
} from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../../redux/action";
import { Link } from "react-router-dom";
import { imageUrl } from "../../../shared/imageUrl";
let slidesToShow = 5;

const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {/* {currentSlide !== 0 && ( */}
      <div className={className} onClick={onClick}>
        <ArrowBackIcon style={{ color: "#fff", fontSize: "27px" }} />
      </div>
      {/* )} */}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  console.log(props);
  return (
    <>
      {/* {currentSlide !== slideCount - slidesToShow && ( */}
      <div className={className} onClick={onClick}>
        <ArrowForwardIcon
          style={{ color: "#fff", fontSize: "27px" }}
          className="ArrowForwardIcon"
        />
      </div>
      {/* )} */}
    </>
  );
};
const carouselProperties = {
  dots: true,
  infinite: false,
  speed: 2000,
  slidesToShow: 3,
  centerPadding: "-200px",
  slidesToScroll: 1,
  initialSlide: 1,
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
      },
    },
  ],
};

const RentalCaurosel = (props) => {
  let data = {
    token: props.login?.login?.token,
  };

  useEffect(() => {
    props.onPropertiesGetData(data);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <div style={{ margin: "30px" }} className="carousel">
      <Slider {...carouselProperties}>
        {props.properties?.properties?.length > 0 &&
          props.properties?.properties?.map((home, index) => {
            // console.log("home rent", home.posting_property_for);
            if (home.posting_property_for == "Rent")
              return (
                <Col md={12} key={index}>
                  <Card className="list-cards">
                    <img
                    src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                      // src={`http://www.omra.in/wp-data-1/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                      width="100%"
                      height="200px"
                    />
                    <CardBody>
                      <ul className="list-group">
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Property For :
                          </span>
                          &nbsp;
                          {home.posting_property_for}
                        </li>
                        <li className="list-group-item ">
                          <span className="font-weight-bold">
                            Property Type :
                          </span>
                          &nbsp;
                          {home.property_type}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Price :</span>
                          &nbsp; &#8377; {home.property_price}
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-bold">Location :</span>
                          &nbsp; {home.prop_city},{home.prop_area}
                        </li>
                      </ul>
                    </CardBody>
                    <CardFooter className="justify-content-center d-flex">
                      <Link
                        to={`/property/${home.id}`}
                        className="btn btn-blue btn-sm text-white"
                      >
                        Read More
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
              );
          })}
      </Slider>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    properties: state.properties,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RentalCaurosel);
