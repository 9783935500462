import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Col, InputGroup, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../custom/CustomInput";
import * as Yup from "yup";
// import GoogleButton from "react-google-button";
import * as actions from "../../redux/action";
import { connect } from "react-redux";
import logo from "../../assets/images/true_homes_logo.png";
//import CircularProgres from "../Loaders/CircularProgres";
import LinerLoader from "../Loaders/LinerLoader";

const Login = (props) => {
  const navigate = useNavigate();
  const toggle = () => {
    navigate("/");
  };
  const handleSubmit = (values, setSubmitting) => {
    let data = {
      email: values.email,
      password: values.password,
    };
    console.log(data);
    props.postLogin(data, toggle);
    setSubmitting(false);
    return;
  };
  return (
    <React.Fragment>
      <div>
        <div className="auth-wrapper align-items-center">
          <div className="container" style={{ paddingTop: 35 }}>
            <div className="no-gutters justify-content-center row">
              <div className="bg-light col-md-6 col-lg-4 login-card">
                <div className="justify-content-center d-flex">
                  {/* {props.login?.isLoading && <LinerLoader />} */}
                  <img src={logo} width="250px" height="75px" />
                </div>
                <div className="p-4">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .required("Enter Your Email")
                        .email("Invalid Email address"),
                      password: Yup.string().required("Enter Your Password"),
                    })}
                  >
                    {(formProps) => (
                      <Form className="mt-3">
                        <Row className="form-group">
                          <Col md={12}>
                            <Label htmlFor="email" className="font-weight-bold">
                              Email
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="text"
                                name="email"
                                id="email"
                                placeholder="yourname@company.com"
                                className={
                                  "form-control" +
                                  (formProps.errors.email &&
                                  formProps.touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col md={12}>
                            <Label
                              htmlFor="password"
                              className="font-weight-bold"
                            >
                              Password
                            </Label>
                            <InputGroup>
                              <Field
                                component={CustomInput}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="* * * * * *"
                                className={
                                  "form-control" +
                                  (formProps.errors.password &&
                                  formProps.touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Link to="/forgot-password" className="signup-link">
                            Reset Password
                          </Link>
                        </Row>
                        <div className="mt-3 mb-3 row">
                          <div className="col-12">
                            <Button
                              type="submit"
                              // disabled={formProps.isSubmitting}
                              className="bg-blue"
                              size="md"
                              block
                            >
                              LOGIN
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="mt-3">
                    {props.login?.isLoading && <LinerLoader />}
                  </div>
                  <div className="mt-3">
                    Don't have an account ?{" "}
                    <Link to="/register" className="signup-link">
                      Sign Up
                    </Link>
                  </div>
                  {/* <h4 className="text-center mt-3 font-weight-bold">Or</h4>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-8">
                      <GoogleButton
                        onClick={() => {
                          console.log("Google button clicked");
                        }}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postLogin: (data, toggle) => {
    dispatch(actions.postLogin(data, toggle));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
