import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";
import storage from "redux-persist/es/storage";
import { Login } from "./reducers/login";
import { Register } from "./reducers/RRegister";
import { Properties } from "./reducers/RProperties";
import { Amenities } from "./reducers/RAmenities";
import { Buildings } from "./reducers/RBuildings";
import { SingleBuildings } from "./reducers/RSingleBuildings";
import { SingleProperties } from "./reducers/RSingleProperties";
import { FilterProperties } from "./reducers/RFilterProperties";
import { CommertialFilter } from "./reducers/RCommertialSearch";
import { Forgot } from "./reducers/RForgot";
import { Update } from "./reducers/RUpdate";
const config = {
  key: "true-homes",
  storage,
  debug: true,
};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const configureStore = () => {
  const store = createStore(
    persistCombineReducers(config, {
      login: Login,
      register: Register,
      properties: Properties,
      amenities: Amenities,
      buildings: Buildings,
      singlebuildings: SingleBuildings,
      singleproperties: SingleProperties,
      filterproperties: FilterProperties,
      cfilter: CommertialFilter,
      forgot:Forgot,
      update:Update
    }),
    composeEnhancer(applyMiddleware(thunk, logger))
  );
  const persistor = persistStore(store);
  return { persistor, store };
};
