import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  CardFooter,
  Row,
  Label,
  InputGroup,
  Button,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as actions from "../../redux/action";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@mui/material/MenuItem";
import LinerLoader from "../Loaders/LinerLoader";
import ActionProperties from "./ActionProperties";
import CircularProgres from "../Loaders/CircularProgres";
import UploadImages from "./UploadImages";
import { imageUrl } from "../../shared/imageUrl";

const MyProperties = (props) => {
  let data = {
    token: props.login?.login?.token,
  };
  const [modal, setModal] = useState(false);
  useEffect(() => {
    props.onPropertiesGetData(data);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("values in properties:", values);

    let property = {
      owner_id: props.login?.login.user ? props.login.login?.user.id : "",
      name: props.login?.login?.user?.name,
      posting_property_for: values.posting_property_for,
      property_type: values.property_type,
      building_name: values.building_name,
      units: values.units,
      bedrooms: values.bedrooms,
      balcony: values.balcony,
      no_bathrooms: values.no_bathrooms,
      total_floors: values.total_floors,
      property_floor: values.property_floor,
      furnishing_type: values.furnishing_type,
      property_area: values.property_area,
      carpet_area: values.carpet_area,
      sqft_area: values.sqft_area,
      desccription: values.desccription,
      address: values.address,
      property_status: values.property_status,
      property_age: values.property_age,
      uc_date: values.uc_date,
      property_price: values.property_price,
      proirity: values.proirity,
      prop_pincode: values.prop_pincode,
      prop_long: values.prop_long,
      prop_lat: values.prop_lat,
      prop_city: values.prop_city,
      prop_area: values.prop_area,
      prop_deposit_price: values.prop_deposit_price,
      ld_length: values.ld_length,
      ld_breadth: values.ld_breadth,
      ld_area: values.ld_area,
      ld_specs: values.ld_specs,
      ld_boundary_walls: values.ld_boundary_walls,
      pd_colony_name: values.pd_colony_name,
      pd_construction_done: values.pd_construction_done,
      pd_boundary_walls: values.pd_boundary_walls,
      pd_gated_colony: values.pd_gated_colony,
      token_price: values.token_price,
      lock_in_period: values.lock_in_period,
      is_featured: values.is_featured,
      is_home: values.is_home,
      test: values.test,
    };
    console.log("Data of users:", property);
    props.onPostPropertiesData(data, property, toggle, setSubmitting);
    setSubmitting(true);
    return;
  };

  return (
    <React.Fragment>
      <Card className="mt-3 p-3 w-100 container">
        <CardHeader className="bg-gradient-yellow p-2 text-dark">
          <div className="d-flex align-items-center justify-content-between">
            <strong className="pl-2 text-blue">My Properties Details</strong>
            <div>
              <Button
                className="btn-blue btn-sm p-2"
                onClick={() => {
                  toggle();
                }}
              >
                <i className="fa fa-plus text-white mr-2" />
                Add New
              </Button>
            </div>
          </div>
          <Modal
            className="modal-xl"
            backdrop="static"
            isOpen={modal}
            toggle={toggle}
          >
            <ModalHeader toggle={toggle} className="d-flex align-items-center">
              Add New Property
            </ModalHeader>

            {props.properties?.isPostLoading && <LinerLoader />}
            <ModalBody className="">
              <Formik
                initialValues={{
                  posting_property_for: "",
                  property_type: "",
                  building_name: "",
                  units: "",
                  bedrooms: "",
                  balcony: "",
                  no_bathrooms: "",
                  total_floors: "",
                  property_floor: "",
                  furnishing_type: "",
                  property_area: "",
                  carpet_area: "",
                  sqft_area: "",
                  desccription: "",
                  address: "",
                  property_status: "",
                  property_age: "",
                  uc_date: "",
                  property_price: "",
                  proirity: "",
                  prop_pincode: "",
                  prop_long: "",
                  prop_lat: "",
                  prop_city: "",
                  prop_area: "",
                  prop_deposit_price: "",
                  ld_length: "",
                  ld_breadth: "",
                  ld_area: "",
                  ld_specs: "",
                  ld_boundary_walls: 0,
                  pd_colony_name: "",
                  pd_construction_done: 0,
                  pd_boundary_walls: 0,
                  pd_gated_colony: 0,
                  token_price: "",
                  lock_in_period: "",
                  is_featured: "",
                  is_home: "",
                  test: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  property_type: Yup.string().required("required"),
                  posting_property_for: Yup.string().required("required"),
                  test: Yup.string().required("required"),
                  sqft_area: Yup.string().required("required"),
                  prop_pincode: Yup.string().required("required"),
                })}
              >
                {(formProps) => {
                  return (
                    <Form>
                      {console.log(
                        `formProps.values properties`,
                        formProps.values
                      )}
                      <Row className="form-group pt-2">
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="posting_property_for"
                              label="Posting Property For *"
                              name="posting_property_for"
                              value={formProps.values.posting_property_for}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.posting_property_for &&
                                Boolean(formProps.errors.posting_property_for)
                              }
                              helperText={
                                formProps.touched.posting_property_for &&
                                formProps.errors.posting_property_for
                              }
                            >
                              <MenuItem value="">
                                Select Posting Property Type *
                              </MenuItem>
                              <MenuItem value="Sell">Sell</MenuItem>
                              <MenuItem value="Rent">Rent</MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="test"
                              label="Your Property Type *"
                              name="test"
                              value={formProps.values.test}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.test &&
                                Boolean(formProps.errors.test)
                              }
                              helperText={
                                formProps.touched.test && formProps.errors.test
                              }
                            >
                              <MenuItem value="">Your Property Type*</MenuItem>
                              <MenuItem value="Residential">
                                Residential
                              </MenuItem>
                              <MenuItem value="Commercial">Commercial</MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        {formProps.values.test == "Residential" ? (
                          <Col md={3}>
                            <InputGroup>
                              <TextField
                                fullWidth
                                select
                                variant="standard"
                                size="small"
                                id="property_type"
                                label="Property Type *"
                                name="property_type"
                                value={formProps.values.property_type}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.property_type &&
                                  Boolean(formProps.errors.property_type)
                                }
                                helperText={
                                  formProps.touched.property_type &&
                                  formProps.errors.property_type
                                }
                              >
                                <MenuItem value="">
                                  Select Property Type
                                </MenuItem>
                                <MenuItem value="Flat">Flat</MenuItem>
                                <MenuItem value="House">House</MenuItem>
                                <MenuItem value="Plot">Plot</MenuItem>
                                <MenuItem value="Farm House">
                                  Farm House
                                </MenuItem>
                              </TextField>
                            </InputGroup>
                          </Col>
                        ) : (
                          <Col md={3}>
                            <InputGroup>
                              <TextField
                                fullWidth
                                select
                                variant="standard"
                                size="small"
                                id="property_type"
                                label="Property Type *"
                                name="property_type"
                                value={formProps.values.property_type}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.property_type &&
                                  Boolean(formProps.errors.property_type)
                                }
                                helperText={
                                  formProps.touched.property_type &&
                                  formProps.errors.property_type
                                }
                              >
                                <MenuItem value="">
                                  Select Property Type
                                </MenuItem>
                                <MenuItem value="Shop">Shop</MenuItem>
                                <MenuItem value="Villa">Villa</MenuItem>
                                <MenuItem value="Showroom">Showroom</MenuItem>
                                <MenuItem value="Office Space">
                                  Office Space
                                </MenuItem>
                                <MenuItem value="WareHouse/Godown">
                                  WareHouse/Godown
                                </MenuItem>
                                <MenuItem value="Agricultural Land">
                                  Agricultural Land
                                </MenuItem>
                                <MenuItem value="Industrial Land">
                                  Industrial Land
                                </MenuItem>
                                <MenuItem value="Commercial Land">
                                  Commercial Land
                                </MenuItem>
                              </TextField>
                            </InputGroup>
                          </Col>
                        )}

                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="building_name"
                              label="Building Name"
                              name="building_name"
                              value={formProps.values.building_name}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.building_name &&
                                Boolean(formProps.errors.building_name)
                              }
                              helperText={
                                formProps.touched.building_name &&
                                formProps.errors.building_name
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="form-group pt-2">
                        {formProps.values.test == "Residential" ? (
                          <Col md={3}>
                            <InputGroup>
                              <TextField
                                fullWidth
                                select
                                variant="standard"
                                size="small"
                                id="bedrooms"
                                label="Bedrooms *"
                                name="bedrooms"
                                value={formProps.values.bedrooms}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.bedrooms &&
                                  Boolean(formProps.errors.bedrooms)
                                }
                                helperText={
                                  formProps.touched.bedrooms &&
                                  formProps.errors.bedrooms
                                }
                              >
                                <MenuItem value="">Select Bedroom *</MenuItem>
                                <MenuItem value="0">1RK</MenuItem>
                                <MenuItem value="1">1BHK</MenuItem>
                                <MenuItem value="2">2BHK</MenuItem>
                                <MenuItem value="3">3BHK</MenuItem>
                                <MenuItem value="4">4BHK</MenuItem>
                                <MenuItem value="5">5BHK</MenuItem>
                              </TextField>
                            </InputGroup>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="balcony"
                              label="Balcony"
                              name="balcony"
                              value={formProps.values.balcony}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.balcony &&
                                Boolean(formProps.errors.balcony)
                              }
                              helperText={
                                formProps.touched.balcony &&
                                formProps.errors.balcony
                              }
                            >
                              <MenuItem value="0">Select Balcony</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              select
                              fullWidth
                              variant="standard"
                              size="small"
                              id="no_bathrooms"
                              name="no_bathrooms"
                              label="Total Bathrooms"
                              value={formProps.values.no_bathrooms}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.no_bathrooms &&
                                Boolean(formProps.errors.no_bathrooms)
                              }
                              helperText={
                                formProps.touched.no_bathrooms &&
                                formProps.errors.no_bathrooms
                              }
                            >
                              <MenuItem value="0">Select Bathrooms</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="total_floors"
                              label="Total Floors"
                              name="total_floors"
                              value={formProps.values.total_floors}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.total_floors &&
                                Boolean(formProps.errors.total_floors)
                              }
                              helperText={
                                formProps.touched.total_floors &&
                                formProps.errors.total_floors
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="form-group pt-2">
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="property_floor"
                              label="Property Floor *"
                              name="property_floor"
                              value={formProps.values.property_floor}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.property_floor &&
                                Boolean(formProps.errors.property_floor)
                              }
                              helperText={
                                formProps.touched.property_floor &&
                                formProps.errors.property_floor
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="furnishing_type"
                              label="Select Furnishing Type *"
                              name="furnishing_type"
                              value={formProps.values.furnishing_type}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.furnishing_type &&
                                Boolean(formProps.errors.furnishing_type)
                              }
                              helperText={
                                formProps.touched.furnishing_type &&
                                formProps.errors.furnishing_type
                              }
                            >
                              <MenuItem value="">Select Furnishing</MenuItem>
                              <MenuItem value="Unfurnished">
                                Unfurnished
                              </MenuItem>
                              <MenuItem value="Semi-Unfurnished">
                                Semi-Unfurnished
                              </MenuItem>
                              <MenuItem value="Fully-Furnished">
                                Fully-Furnished
                              </MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="property_area"
                              label="Property Area *"
                              name="property_area"
                              value={formProps.values.property_area}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.property_area &&
                                Boolean(formProps.errors.property_area)
                              }
                              helperText={
                                formProps.touched.property_area &&
                                formProps.errors.property_area
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="carpet_area"
                              label="Carpet Area (Sq.ft)"
                              name="carpet_area"
                              value={formProps.values.carpet_area}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.carpet_area &&
                                Boolean(formProps.errors.carpet_area)
                              }
                              helperText={
                                formProps.touched.carpet_area &&
                                formProps.errors.carpet_area
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="form-group pt-2">
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="sqft_area"
                              label="Square Feet Area (Sq.ft) *"
                              name="sqft_area"
                              value={formProps.values.sqft_area}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.sqft_area &&
                                Boolean(formProps.errors.sqft_area)
                              }
                              helperText={
                                formProps.touched.sqft_area &&
                                formProps.errors.sqft_area
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="desccription"
                              label="Description *"
                              name="desccription"
                              value={formProps.values.desccription}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.desccription &&
                                Boolean(formProps.errors.desccription)
                              }
                              helperText={
                                formProps.touched.desccription &&
                                formProps.errors.desccription
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="property_status"
                              label="Property Status *"
                              name="property_status"
                              value={formProps.values.property_status}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.property_status &&
                                Boolean(formProps.errors.property_status)
                              }
                              helperText={
                                formProps.touched.property_status &&
                                formProps.errors.property_status
                              }
                            >
                              <MenuItem value="">
                                Select Property Status
                              </MenuItem>
                              <MenuItem value="Ready To Move">
                                Ready To Move
                              </MenuItem>
                              <MenuItem value="Under Construction">
                                Under Construction
                              </MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        {formProps.values.property_status ==
                        "Under Construction" ? (
                          <Col md={3}>
                            <Label>UC Date</Label>
                            <InputGroup>
                              <TextField
                                fullWidth
                                type="date"
                                variant="standard"
                                size="small"
                                id="uc_date"
                                name="uc_date"
                                value={formProps.values.uc_date}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.uc_date &&
                                  Boolean(formProps.errors.uc_date)
                                }
                                helperText={
                                  formProps.touched.uc_date &&
                                  formProps.errors.uc_date
                                }
                              />
                            </InputGroup>
                          </Col>
                        ) : (
                          <Col md={3}>
                            <InputGroup>
                              <TextField
                                fullWidth
                                select
                                variant="standard"
                                size="small"
                                id="property_age"
                                label="Property Age"
                                name="property_age"
                                value={formProps.values.property_age}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.property_age &&
                                  Boolean(formProps.errors.property_age)
                                }
                                helperText={
                                  formProps.touched.property_age &&
                                  formProps.errors.property_age
                                }
                              >
                                <MenuItem value="">
                                  Select Property Age
                                </MenuItem>
                                <MenuItem value="">
                                  Select Property Age
                                </MenuItem>
                                <MenuItem value="Newly Constructed">
                                  Newly Constructed
                                </MenuItem>
                                <MenuItem value="Less than 5 years">
                                  Less than 5 Years
                                </MenuItem>
                                <MenuItem value="5-10 years">
                                  5-10 Years
                                </MenuItem>
                                <MenuItem value="10-15 years">
                                  10-15 Years
                                </MenuItem>
                                <MenuItem value="15-20 years">
                                  15-20 Years
                                </MenuItem>
                                <MenuItem value="Above 20 years">
                                  Above 20 Years
                                </MenuItem>
                              </TextField>
                            </InputGroup>
                          </Col>
                        )}
                      </Row>
                      <Row className="form-group pt-2">
                        {/* <Col md={3}>
                        <InputGroup>
                          <TextField
                            fullWidth
                            select
                            variant="standard"
                            size="small"
                            id="property_age"
                            label="Property Age"
                            name="property_age"
                            value={formProps.values.property_age}
                            onChange={formProps.handleChange}
                            error={
                              formProps.touched.property_age &&
                              Boolean(formProps.errors.property_age)
                            }
                            helperText={
                              formProps.touched.property_age &&
                              formProps.errors.property_age
                            }
                          >
                            <MenuItem value="">Select Property Age</MenuItem>
                            <MenuItem value="Newl constructed">
                              Newly constructed
                            </MenuItem>
                            <MenuItem value="Less than 5 Years">
                              Less than 5 Years
                            </MenuItem>
                            <MenuItem value="5-10 Years">5-10 Years</MenuItem>
                            <MenuItem value="10-15 Years">10-15 Years</MenuItem>
                            <MenuItem value="15-20 Years">15-20 Years</MenuItem>
                            <MenuItem value="Above 20 Years">
                              Above 20 Years
                            </MenuItem>
                          </TextField>
                        </InputGroup>
                      </Col> */}
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              type="number"
                              variant="standard"
                              size="small"
                              id="property_price"
                              label="Property Price *"
                              name="property_price"
                              value={formProps.values.property_price}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.property_price &&
                                Boolean(formProps.errors.property_price)
                              }
                              helperText={
                                formProps.touched.property_price &&
                                formProps.errors.property_price
                              }
                            />
                          </InputGroup>
                        </Col>

                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="proirity"
                              label="Proirity"
                              name="proirity"
                              value={formProps.values.proirity}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.proirity &&
                                Boolean(formProps.errors.proirity)
                              }
                              helperText={
                                formProps.touched.proirity &&
                                formProps.errors.proirity
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="units"
                              label="Units"
                              name="units"
                              value={formProps.values.units}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.units &&
                                Boolean(formProps.errors.units)
                              }
                              helperText={
                                formProps.touched.units &&
                                formProps.errors.units
                              }
                            />
                          </InputGroup>
                        </Col>
                        {formProps.values.posting_property_for == "Rent" ? (
                          <Col md={3}>
                            <InputGroup>
                              <TextField
                                fullWidth
                                variant="standard"
                                size="small"
                                id="prop_deposit_price"
                                label="Property Deposit Price *"
                                name="prop_deposit_price"
                                value={formProps.values.prop_deposit_price}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.prop_deposit_price &&
                                  Boolean(formProps.errors.prop_deposit_price)
                                }
                                helperText={
                                  formProps.touched.prop_deposit_price &&
                                  formProps.errors.prop_deposit_price
                                }
                              />
                            </InputGroup>
                          </Col>
                        ) : (
                          <Col md={3}>
                            <InputGroup>
                              <TextField
                                fullWidth
                                type="number"
                                variant="standard"
                                size="small"
                                id="token_price"
                                label="Token Price *"
                                name="token_price"
                                value={formProps.values.token_price}
                                onChange={formProps.handleChange}
                                error={
                                  formProps.touched.token_price &&
                                  Boolean(formProps.errors.token_price)
                                }
                                helperText={
                                  formProps.touched.token_price &&
                                  formProps.errors.token_price
                                }
                              />
                            </InputGroup>
                          </Col>
                        )}
                      </Row>
                      <Row className="form-group pt-2">
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="prop_pincode"
                              label="Property Pincode *"
                              name="prop_pincode"
                              value={formProps.values.prop_pincode}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.prop_pincode &&
                                Boolean(formProps.errors.prop_pincode)
                              }
                              helperText={
                                formProps.touched.prop_pincode &&
                                formProps.errors.prop_pincode
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              type="number"
                              variant="standard"
                              size="small"
                              id="prop_long"
                              label="Property Longitude"
                              name="prop_long"
                              value={formProps.values.prop_long}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.prop_long &&
                                Boolean(formProps.errors.prop_long)
                              }
                              helperText={
                                formProps.touched.prop_long &&
                                formProps.errors.prop_long
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              type="number"
                              id="prop_lat"
                              label="Property Latitude"
                              name="prop_lat"
                              value={formProps.values.prop_lat}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.prop_lat &&
                                Boolean(formProps.errors.prop_lat)
                              }
                              helperText={
                                formProps.touched.prop_lat &&
                                formProps.errors.prop_lat
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              id="prop_city"
                              label="Property City *"
                              name="prop_city"
                              value={formProps.values.prop_city}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.prop_city &&
                                Boolean(formProps.errors.prop_city)
                              }
                              helperText={
                                formProps.touched.prop_city &&
                                formProps.errors.prop_city
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="form-group pt-2">
                        {/* <Col md={3}>
                        <InputGroup>
                          <TextField
                            fullWidth
                            variant="standard"
                            size="small"
                            id="prop_area"
                            label="Property Area *"
                            name="prop_area"
                            value={formProps.values.prop_area}
                            onChange={formProps.handleChange}
                            error={
                              formProps.touched.prop_area &&
                              Boolean(formProps.errors.prop_area)
                            }
                            helperText={
                              formProps.touched.prop_area &&
                              formProps.errors.prop_area
                            }
                          />
                        </InputGroup>
                      </Col> */}

                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="is_featured"
                              label="Add Featured"
                              name="is_featured"
                              value={formProps.values.is_featured}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.is_featured &&
                                Boolean(formProps.errors.is_featured)
                              }
                              helperText={
                                formProps.touched.is_featured &&
                                formProps.errors.is_featured
                              }
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value="1">Yes</MenuItem>
                              <MenuItem value="2">No</MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                        <Col md={3}>
                          <InputGroup>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              size="small"
                              id="is_home"
                              label="Add Home"
                              name="is_home"
                              value={formProps.values.is_home}
                              onChange={formProps.handleChange}
                              error={
                                formProps.touched.is_home &&
                                Boolean(formProps.errors.is_home)
                              }
                              helperText={
                                formProps.touched.is_home &&
                                formProps.errors.is_home
                              }
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value="1">Yes</MenuItem>
                              <MenuItem value="2">No</MenuItem>
                            </TextField>
                          </InputGroup>
                        </Col>
                      </Row>
                      {formProps.values.test == "Commercial" ? (
                        <>
                          <Row className="form-group pt-2">
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="ld_length"
                                  label="Land Length"
                                  name="ld_length"
                                  value={formProps.values.ld_length}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.ld_length &&
                                    Boolean(formProps.errors.ld_length)
                                  }
                                  helperText={
                                    formProps.touched.ld_length &&
                                    formProps.errors.ld_length
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="ld_breadth"
                                  label="Land Breadth"
                                  name="ld_breadth"
                                  value={formProps.values.ld_breadth}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.ld_breadth &&
                                    Boolean(formProps.errors.ld_breadth)
                                  }
                                  helperText={
                                    formProps.touched.ld_breadth &&
                                    formProps.errors.ld_breadth
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="ld_area"
                                  label="Land Area"
                                  name="ld_area"
                                  value={formProps.values.ld_area}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.ld_area &&
                                    Boolean(formProps.errors.ld_area)
                                  }
                                  helperText={
                                    formProps.touched.ld_area &&
                                    formProps.errors.ld_area
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="ld_specs"
                                  label="Land Specs"
                                  name="ld_specs"
                                  value={formProps.values.ld_specs}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.ld_specs &&
                                    Boolean(formProps.errors.ld_specs)
                                  }
                                  helperText={
                                    formProps.touched.ld_specs &&
                                    formProps.errors.ld_specs
                                  }
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="form-group pt-2">
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="ld_boundary_walls"
                                  label="Land Boundary Walls"
                                  name="ld_boundary_walls"
                                  value={formProps.values.ld_boundary_walls}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.ld_boundary_walls &&
                                    Boolean(formProps.errors.ld_boundary_walls)
                                  }
                                  helperText={
                                    formProps.touched.ld_boundary_walls &&
                                    formProps.errors.ld_boundary_walls
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="pd_colony_name"
                                  label="Plot Colony Name"
                                  name="pd_colony_name"
                                  value={formProps.values.pd_colony_name}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.pd_colony_name &&
                                    Boolean(formProps.errors.pd_colony_name)
                                  }
                                  helperText={
                                    formProps.touched.pd_colony_name &&
                                    formProps.errors.pd_colony_name
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="pd_construction_done"
                                  label="Plot Construction Done"
                                  name="pd_construction_done"
                                  value={formProps.values.pd_construction_done}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.pd_construction_done &&
                                    Boolean(
                                      formProps.errors.pd_construction_done
                                    )
                                  }
                                  helperText={
                                    formProps.touched.pd_construction_done &&
                                    formProps.errors.pd_construction_done
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="pd_boundary_walls"
                                  label="Plot Boundary Walls"
                                  name="pd_boundary_walls"
                                  value={formProps.values.pd_boundary_walls}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.pd_boundary_walls &&
                                    Boolean(formProps.errors.pd_boundary_walls)
                                  }
                                  helperText={
                                    formProps.touched.pd_boundary_walls &&
                                    formProps.errors.pd_boundary_walls
                                  }
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="form-group pt-2">
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="pd_gated_colony"
                                  label="Plot Gated Colony"
                                  name="pd_gated_colony"
                                  value={formProps.values.pd_gated_colony}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.pd_gated_colony &&
                                    Boolean(formProps.errors.pd_gated_colony)
                                  }
                                  helperText={
                                    formProps.touched.pd_gated_colony &&
                                    formProps.errors.pd_gated_colony
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3}>
                              <InputGroup>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  id="lock_in_period"
                                  label="Lock In Period"
                                  name="lock_in_period"
                                  value={formProps.values.lock_in_period}
                                  onChange={formProps.handleChange}
                                  error={
                                    formProps.touched.lock_in_period &&
                                    Boolean(formProps.errors.lock_in_period)
                                  }
                                  helperText={
                                    formProps.touched.lock_in_period &&
                                    formProps.errors.lock_in_period
                                  }
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}

                      <Row style={{ justifyContent: "center" }}>
                        <Col md={4}>
                          <Button type="reset" color="danger" block>
                            <b>Reset</b>
                          </Button>
                        </Col>
                        <Col md={4}>
                          <Button
                            type="submit"
                            disabled={formProps.isSubmitting}
                            color="primary"
                            block
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </ModalBody>
            <ModalFooter>
              {props.properties?.isPostLoading && <LinerLoader />}
            </ModalFooter>
          </Modal>
        </CardHeader>
      </Card>
      <Container>
        {props.properties?.isLoading ? (
          <CircularProgres />
        ) : (
          <Row className="mt-3">
            {props.properties?.properties?.length > 0 &&
              props.properties?.properties.map((home, index) => {
                // console.log("home", home);
                if (props.login?.login?.user.id == home.owner?.id)
                  return (
                    <Col md={4} key={index}>
                      <Card className="list-cards">
                        <img
                          src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                          // src={`https://uditsolutions.in/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                          width="100%"
                          height="200px"
                        />
                        <CardBody>
                          <ul className="list-group">
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Property For :
                              </span>
                              &nbsp;
                              {home.posting_property_for}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Property Type :
                              </span>
                              &nbsp;
                              {home.property_type}
                            </li>
                            <li className="list-group-item">
                              <span className="font-weight-bold">Price :</span>
                              &nbsp; &#8377; {home.property_price}
                            </li>
                            <li className="list-group-item">
                              <span className="font-weight-bold">
                                Location :
                              </span>
                              &nbsp; {home.prop_city},{home.prop_area}
                            </li>
                          </ul>
                        </CardBody>
                        <CardFooter className="">
                          <Row>
                            <Col md={6}>
                              <Link
                                to={`/property/${home.id}`}
                                className="btn btn-blue btn-sm text-white"
                                style={{ width: "100%" }}
                              >
                                Read More
                              </Link>
                            </Col>
                            <Col md={6}>
                              <ActionProperties data={home} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <UploadImages data={home} />
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </Col>
                  );
              })}
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    properties: state.properties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),
    onPostPropertiesData: (data, property, toggle, setSubmitting) =>
      dispatch(
        actions.postPropertiesData(data, property, toggle, setSubmitting)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProperties);
