import * as ActionTypes from "./ActionTypes";
import { baseUrl } from "../../shared/baseUrl";
import swal from "sweetalert";

const myheader = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  mode: "no-cors",
};

export const postRegister = (register, toggle) => (dispatch) => {
  dispatch(registerLoading());
  return fetch(baseUrl + "/customer-register", {
    method: "POST",
    headers: myheader,
    body: JSON.stringify(register),
  })
    .then((response) => response.json())

    .then((res) => {
      //console.log("res reg", res);
      if (res.error != undefined) {
        if (res.error.email == "The email has already been taken.") {
          swal({
            title: "This email is already registered. Please Login.",
            icon: "warning",
          });
          // alert("This email is already registered. Please Login.");
        }
      } else {
        if (toggle) {
          toggle();
        }
        //console.log("register", res);
        swal({
          title: "Thank you for registering with True Homes.",
          icon: "success",
        });
        dispatch(addRegister(res));
        //console.log("register response", res);
      }
    })
    .catch((error) => {
      dispatch(registerFailed(error));
    });
};

export const addRegister = (register) => ({
  type: ActionTypes.ADD_REGISTER,
  register: register,
});

export const registerLoading = () => ({
  type: ActionTypes.REGISTER_LOADING,
});

export const registerFailed = (errMess) => ({
  type: ActionTypes.REGISTER_FAILED,
  payload: errMess,
});
