import React, { useState, useEffect } from "react";
import "./propert.css";
import CircularProgres from "../Loaders/CircularProgres";
import { Carousel } from "react-bootstrap";
import { useSelector, connect } from "react-redux";
import {
  Card,
  Col,
  Container,
  Row,
  InputGroup,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { IoArrowBackCircle } from "react-icons/io5";
import * as Yup from "yup";
import CustomSelect from "../custom/CustomSelect";
import { FaHome } from "react-icons/fa";
import * as actions from "../../redux/action";
import ReactPlayer from "react-player";
import GoogleMapProp from "./GoogleMapProp";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BuyFilter from "./BuyFilter";
import RentFilter from "./RentFilter";
import CommertialFilter from "./CommertialFilter";
import { imageUrl } from "../../shared/imageUrl";
import { Stack } from "@mui/material";

const Property = (props) => {
  const { id } = useParams();
  const properties = useSelector((state) => state.properties);
  const [index, setIndex] = useState(0);
  let data = {
    token: props.login?.login?.token,
    id: id,
  };

  useEffect(() => {
    // props.onAmenitiesGetData(data);
    props.singlepropertiesGetData(data);
    props.onPropertiesGetData(data);
  }, []);

  let [homes, setHomes] = useState(props.singleproperties.singleproperties);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      {props.singleproperties.isLoading ? (
        <CircularProgres />
      ) : (
        <div id="all-details">
          <section id="slider">
            <Carousel activeIndex={index} onSelect={handleSelect} fade>
              {props.singleproperties?.singleproperties?.images?.length > 0 &&
                props.singleproperties?.singleproperties?.images?.map(
                  (img, index) => {
                    return (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={`${imageUrl}/PropertyPhotos/${img.file_name}`}
                          alt=""
                        />
                      </Carousel.Item>
                    );
                  }
                )}
            </Carousel>
          </section>
          <section className="description">
            <div className="row">
              <div className="col-md-1 justify-content-center">
                <Link to="/property-listing" className="d-flex">
                  <IoArrowBackCircle size={35} />
                </Link>
              </div>
              <div className="col-md-8">
                <h2 className="font-weight-bold">
                  Property Type :- &nbsp;
                  <span className="text-blue">
                    {props.singleproperties?.singleproperties?.property_type}
                  </span>
                </h2>
                <p>
                  <i className="fa fa-map-marker" />
                  {props.singleproperties?.singleproperties?.prop_city &&
                    props.singleproperties?.singleproperties?.prop_area}
                </p>
              </div>
            </div>
            <div>
              <Row>
                <Col md={8}>
                  <Card className="all-info-cards">
                    <h5 className="text-blue font-weight-bold">Overview</h5>
                    <ul className="list-group mt-4">
                      <li className="list-group-item ">
                        <span className="font-weight-bold">Updated On:</span>
                        &nbsp;
                        {props.singleproperties?.singleproperties?.updated_at ??
                          "N/A"}
                      </li>

                      <li className="list-group-item ">
                        <span className="font-weight-bold">Bedrooms :</span>
                        &nbsp;
                        {props.singleproperties?.singleproperties?.bedrooms ??
                          "N/A"}
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-bold">Bathrooms :</span>
                        &nbsp; &#8377;{" "}
                        {props.singleproperties?.singleproperties
                          ?.no_bathrooms ?? "N/A"}
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-bold">Balcony :</span>
                        &nbsp;{" "}
                        {props.singleproperties?.singleproperties?.balcony ??
                          "N/A"}
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-bold"> Square Area :</span>
                        &nbsp;{" "}
                        {props.singleproperties?.singleproperties?.sqft_area ??
                          "N/A"}
                      </li>
                    </ul>
                  </Card>
                  <Card className="all-info-cards mt-4">
                    <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <b>Address</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <ul className="list-group mt-4">
                            <li className="list-group-item ">
                              <span className="font-weight-bold">Address:</span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.address ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">City:</span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.prop_city ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">Area:</span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.prop_area ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">Pincode:</span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.prop_pincode ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">Pincode:</span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.prop_pincode ?? "N/A"}
                            </li>
                          </ul>
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </Card>
                  <Card className="all-info-cards mt-4">
                    <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <b>Details</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <ul className="list-group mt-4">
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Property Id:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties?.id ??
                                "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Building Name:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.building_name ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">Price:</span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.property_price ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Property Type:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.property_type ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Total Floors:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.total_floors ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Bedrooms:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.bedrooms ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Bathrooms:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.no_bathrooms ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Property Area:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.property_area ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Carpet Area:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.carpet_area ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold">
                                Sq.ft Area:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.sqft_area ?? "N/A"}
                            </li>
                            <li className="list-group-item ">
                              <span className="font-weight-bold ">
                                Property Floor:
                              </span>
                              &nbsp;
                              {props.singleproperties?.singleproperties
                                ?.property_floor ?? "N/A"}
                            </li>
                          </ul>
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </Card>
                  <Card className="all-info-cards mt-4">
                    <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <b>Amenities</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <div className="row" key={index}>
                            {props.singleproperties.singleproperties
                              ?.amenity_details?.length > 0 &&
                              props.singleproperties.singleproperties?.amenity_details?.map(
                                (el, index) => {
                                  return (
                                    <div className="col-md-4">
                                      <p>
                                        <img
                                          src={`${imageUrl}/AmenityImages/${el.amenity?.image}`}
                                          width={"40px"}
                                          height={"40px"}
                                        />
                                        &nbsp;{el.amenity?.name}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </Card>
                  <Card className="all-info-cards mt-4">
                    <h6>Description</h6>
                    <p className="mt-3">
                      {props.singleproperties?.singleproperties?.desccription}
                    </p>
                  </Card>

                  <Card className="all-info-cards mt-4">
                    <GoogleMapProp
                      data={props.singleproperties?.singleproperties}
                    />
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="all-info-cards">
                    <h6 className="text-center mt-3">Advanced Search</h6>

                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            centered
                          >
                            <Tab label="BUY" value="1" />
                            <Tab label="RENT" value="2" />
                            <Tab label="COMMERCIAL" value="3" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <BuyFilter />
                        </TabPanel>
                        <TabPanel value="2">
                          <RentFilter />
                        </TabPanel>
                        <TabPanel value="3">
                          <CommertialFilter />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Card>
                  <Card className="all-info-cards mt-4">
                    <h6>Our Listings</h6>
                    <div className="mt-3 listing-list">
                      <ul>
                        <li>
                          <Link to="/all-flat" className="listing-list-link">
                            Flat
                          </Link>
                        </li>
                        <hr />
                        <li>
                          <Link to="/all-house" className="listing-list-link">
                            House
                          </Link>
                          {/* <span>(8)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link
                            to="/all-farm-house"
                            className="listing-list-link"
                          >
                            Farm House
                          </Link>
                          {/* <span>(5)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link
                            to="/all-showroom"
                            className="listing-list-link"
                          >
                            Showroom
                          </Link>
                          {/* <span>(1)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link to="/all-plot" className="listing-list-link">
                            Plot
                          </Link>
                          {/* <span>(1)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link to="/all-shop" className="listing-list-link">
                            Shop
                          </Link>
                          {/* <span>(2)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link
                            to="/office-space"
                            className="listing-list-link"
                          >
                            Office Space
                          </Link>
                          {/* <span>(4)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link
                            to="/agricultural-land"
                            className="listing-list-link"
                          >
                            Agricultural Land
                          </Link>
                          {/* <span>(4)</span> */}
                        </li>
                        <hr />
                        <li>
                          <Link
                            to="/property-listing"
                            className="listing-list-link"
                          >
                            Other
                          </Link>
                        </li>
                        <hr />
                      </ul>
                    </div>
                  </Card>
                  <Card className="all-info-cards mt-4">
                    <h6>Verified Properties</h6>
                    <Row className="">
                      {props.properties?.isLoading ? (
                        <CircularProgres />
                      ) : props.properties?.properties?.length > 0 ? (
                        props.properties?.properties
                          ?.filter(
                            (item) =>
                              item.is_featured == "1" &&
                              item.approved_id !== null
                          )
                          ?.map((home, index) => {
                            if (home.is_featured == "1")
                              return (
                                <Col md={12} key={index}>
                                  <Card className="list-cards">
                                    <div className="row ">
                                      <div
                                        class="col-md-6"
                                        style={{ paddingRight: "0px" }}
                                      >
                                        <Link
                                          to={`/property/${home.id}`}
                                          style={{
                                            textDecoration: "none",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <img
                                            src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                                            width="100%"
                                            height="110px"
                                            className="card-img"
                                          />
                                        </Link>
                                      </div>
                                      <div
                                        class="col-md-6"
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <div
                                          className="card-body"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          <p
                                            className="text-dark"
                                            style={{ margin: "0" }}
                                          >
                                            For :{home.posting_property_for}
                                          </p>
                                          <p
                                            className="text-warning font-weight-bold"
                                            style={{ margin: "0" }}
                                          >
                                            from :&nbsp; &#8377;{" "}
                                            {home.property_price}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              );
                          })
                      ) : (
                        <Stack
                          sx={{ width: "100%", textAlign: "center" }}
                          spacing={2}
                          className="mb-3 p-4"
                        >
                          No Data Found
                        </Stack>
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    singleproperties: state.singleproperties,
    // amenities: state.amenities,
    properties: state.properties,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // onAmenitiesGetData: (data) => dispatch(actions.amenitiesGetData(data)),
    onSinglebuildingsGetData: (data) =>
      dispatch(actions.singlebuildingsGetData(data)),
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),
    singlepropertiesGetData: (data) =>
      dispatch(actions.singlepropertiesGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Property);
