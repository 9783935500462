import React, { useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import LinerLoader from "../Loaders/LinerLoader";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as actions from "../../redux/action";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  InputGroup,
  CardFooter,
  Card,
  CardBody,
  Label,
  ModalFooter,
} from "reactstrap";
import { MenuItem } from "@mui/material";
import Swal from "sweetalert2";
// import DeleteButton from "../../Helpers/DeleteButton";
// import IconButton from "@mui/material/IconButton";
// import AddBoxIcon from "@mui/icons-material/AddBox";

function UploadImages(props) {
  console.log("props 123", props);
  const accessToken = `${props.login?.login?.token}`;

  let data = {
    token: accessToken,
    id: props.data?.id,
  };

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("values in action property:", values);
    setSubmitting(true);
    const property = new FormData();
    values.photos?.map((file, index) => {
      console.log("photos", file);
      return property.append(`photos[${index}]`, file);
    });

    console.log("Data Post:", property);
    props.postPropertiesImages(data, property, toggle, setSubmitting);
    return;
  };

  return (
    <div>
      <div>
        <Button
          className="bg-success btn btn-sm text-white"
          onClick={() => {
            toggle();
          }}
          style={{ width: "100%" }}
        >
          {/* <i className="fa fa-edit" aria-hidden="true"></i> */}
          Upload Images
        </Button>

        {/* <Button
          className="bg-danger btn btn-sm text-white"
          onClick={(id) => {
            deleteUsers(data.id);
          }}
        >
          <i className="fa fa-trash text-white" aria-hidden="true" />
          &nbsp;Delete
        </Button> */}
      </div>

      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="d-flex align-items-center">
          Upload Images
        </ModalHeader>
        {/* {props.properties?.isUpdateLoading && <LinerLoader />} */}
        <ModalBody className="">
          <Formik
            initialValues={{
              photos: [],
            }}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <Form>
                {console.log(`formProps.values images`, formProps.values)}
                <Row className="form-group pt-2">
                  <Col md={4}>
                    <Label for="images">Upload Mutliple Image</Label>
                    <InputGroup>
                      <TextField
                        fullWidth
                        variant="standard"
                        id="photos"
                        type="file"
                        name="photos"
                        inputProps={{ multiple: true }}
                        onChange={(e) => {
                          formProps.setFieldValue("photos", [
                            ...e.target.files,
                          ]);
                        }}
                        error={
                          formProps.touched.photos &&
                          Boolean(formProps.errors.photos)
                        }
                        helperText={
                          formProps.touched.images && formProps.errors.images
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  <Col md={4}>
                    <Button type="reset" color="danger" block>
                      <b>Reset</b>
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      type="submit"
                      disabled={formProps.isSubmitting}
                      color="primary"
                      block
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
        {/* <ModalFooter>
          {props.properties?.isUpdateLoading && <LinerLoader />}
        </ModalFooter> */}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    properties: state.properties,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),

    postPropertiesImages: (data, property, toggle, setSubmitting) =>
      dispatch(
        actions.postPropertiesImages(data, property, toggle, setSubmitting)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadImages);
