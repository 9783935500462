import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  CardFooter,
} from "reactstrap";
import * as actions from "../../redux/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CircularProgres from "../Loaders/CircularProgres";
import NoDatafound from "../../assets/images/data.png";
import Stack from "@mui/material/Stack";
import TabPanel from "../home/filter-page/TabPanel";
import { imageUrl } from "../../shared/imageUrl";
// import CircularIndeterminate from "../Loaders/Circular";

const PropertyListing = (props) => {
  let data = {
    token: props.login?.login?.token,
  };

  useEffect(() => {
    props.onPropertiesGetData(data);
  }, []);
  const [properties, setProperties] = useState(
    props.properties?.properties?.filter((item) => item.approved_id !== null)
  );
  console.log("first", properties);
  const filterItem = (propType) => {
    const updateItems = props.properties?.properties.filter((curElm) => {
      return curElm.posting_property_for === propType;
    });
    setProperties(updateItems);
  };
  return (
    <React.Fragment>
      <div className="container-fluid" style={{ padding: "0px" }}>
        <TabPanel />
      </div>
      <Container className="mt-4">
        <Row>
          <Col md={3}></Col>
          <Col md={6} className="text-center">
            <div>
              <button
                className="btn btn-sm btn-blue text-white"
                onClick={() => filterItem("Rent")}
              >
                Rental
              </button>
              <button
                className="btn btn-sm btn-blue text-white"
                onClick={() => filterItem("Sell")}
              >
                Sales
              </button>
              <button
                className="btn btn-sm btn-blue text-white"
                onClick={() => filterItem("Invest")}
              >
                Invest
              </button>
            </div>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-3">
          {props.properties?.isLoading ? (
            <CircularProgres />
          ) : properties.length > 0 ? (
            properties?.map((home, index) => {
              if (home.approved_id !== null)
                return (
                  <Col md={4} key={index}>
                    <Card className="list-cards">
                      <img
                        src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                        width="100%"
                        height="200px"
                      />
                      <CardBody>
                        <ul className="list-group">
                          <li className="list-group-item ">
                            <span className="font-weight-bold">
                              Property For :
                            </span>
                            &nbsp;
                            {home.posting_property_for}
                          </li>
                          <li className="list-group-item ">
                            <span className="font-weight-bold">
                              Property Type :
                            </span>
                            &nbsp;
                            {home.property_type}
                          </li>
                          <li className="list-group-item">
                            <span className="font-weight-bold">Price :</span>
                            &nbsp; &#8377; {home.property_price}
                          </li>
                          <li className="list-group-item">
                            <span className="font-weight-bold">Location :</span>
                            &nbsp; {home.prop_city},{home.prop_area}
                          </li>
                        </ul>
                      </CardBody>
                      <CardFooter className="justify-content-center d-flex">
                        <Link
                          to={`/property/${home.id}`}
                          className="btn btn-blue btn-sm text-white"
                        >
                          Read More
                        </Link>
                      </CardFooter>
                    </Card>
                  </Col>
                );
            })
          ) : (
            <Stack
              sx={{ width: "100%", textAlign: "center" }}
              spacing={2}
              className="mb-3"
            >
              <img src={NoDatafound} width="100%" height="100%" />
            </Stack>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    properties: state.properties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertyListing);
