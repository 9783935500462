import * as actionType from "./ActionTypes";
import axios from "../../axios";
import { baseUrl } from "../../shared/baseUrl";
import Swal from "sweetalert2";

export const buildingsSetData = (buildings) => {
  return {
    type: actionType.BUILDINGS_SET_DATA,
    buildings: buildings,
  };
};

export const buildingsFailData = (error) => {
  return {
    type: actionType.BUILDINGS_FAIL_DATA,
    error: error,
  };
};

export const buildingsLoading = () => {
  return {
    type: actionType.BUILDINGS_LOADING,
  };
};
export const buildingsGetData = (data) => {
  return (dispatch) => {
    dispatch(buildingsLoading());
    axios
      .get(baseUrl + "/buildings", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        console.log(res.data, "res");
        dispatch(buildingsSetData(res.data));
      })
      .catch((error) => dispatch(buildingsFailData(error)));
  };
};
export const deleteBuildingsFail = (error) => {
  return {
    type: actionType.DELETE_BUILDINGS_FAIL,
    error: error,
  };
};

export const deleteBuildings = (id, data) => {
  return (dispatch) => {
    if (id) {
      axios
        .delete(baseUrl + `/buildings/${data.id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + data?.token,
          },
        })
        .then(() => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              dispatch(buildingsGetData(data));
            }
          );
        })
        .catch((error) => dispatch(deleteBuildingsFail()));
    }
  };
};

export const postBuildingsDataStart = () => {
  return {
    type: actionType.POST_BUILDINGS_DATA_START,
  };
};

export const postBuildingsDataFail = (error) => {
  return {
    type: actionType.POST_BUILDINGS_DATA_FAIL,
    error: error,
  };
};

export const postBuildingsDataSuccess = (success) => {
  return {
    type: actionType.POST_BUILDINGS_DATA_SUCCESS,
    success: success,
  };
};

export const buildingsPostLoading = () => {
  return {
    type: actionType.BUILDINGS_POST_LOADING,
  };
};

export const postBuildingsData = (data, building, toggle, setSubmitting) => {
  return (dispatch) => {
    console.log("building", building);
    dispatch(postBuildingsDataStart());
    dispatch(buildingsPostLoading());
    axios
      .post(baseUrl + "/buildings", building, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        dispatch(postBuildingsDataSuccess(res.data));
        dispatch(buildingsGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Created",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(postBuildingsDataFail(error));
      });
  };
};

// upload images
// export const postPropertiesImageDataStart = () => {
//   return {
//     type: actionType.POST_PROPERTIES_IMAGES_DATA_START,
//   };
// };

// export const postPropertiesImageDataFail = (error) => {
//   return {
//     type: actionType.POST_PROPERTIES_IMAGES_DATA_FAIL,
//     error: error,
//   };
// };

// export const postPropertiesImageDataSuccess = (success) => {
//   return {
//     type: actionType.POST_PROPERTIES_IMAGES_DATA_SUCCESS,
//     success: success,
//   };
// };

// export const propertiesImagePostLoading = () => {
//   return {
//     type: actionType.PROPERTIES_IMAGES_POST_LOADING,
//   };
// };

// export const postPropertiesImages = (data, building, toggle, setSubmitting) => {
//   return (dispatch) => {
//     console.log("building", building);
//     dispatch(postPropertiesImageDataStart());
//     dispatch(propertiesImagePostLoading());
//     axios
//       .post(baseUrl + `/bulk-building-photos/${data.id}`, building, {
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + data.token,
//         },
//       })
//       .then((res) => {
//         dispatch(postPropertiesImageDataSuccess(res.data));
//         dispatch(propertiesGetData(data));
//         Swal.fire({
//           position: "success",
//           icon: "success",
//           title: "Successfully Created",
//           showConfirmButton: false,
//           timer: 1500,
//         }).then(() => {
//           if (toggle) {
//             toggle();
//           }
//           if (setSubmitting) {
//             setSubmitting(false);
//           }
//         });
//       })
//       .catch((error) => {
//         if (setSubmitting) {
//           setSubmitting(false);
//         }
//         dispatch(postPropertiesImageDataFail(error));
//       });
//   };
// };

//
export const updateBuildingsDataStart = () => {
  return {
    type: actionType.UPDATE_BUILDINGS_DATA_START,
  };
};

export const buildingsUpdateLoading = () => {
  return {
    type: actionType.BUILDINGS_UPDATE_LOADING,
  };
};

export const updateBuildingsDataFail = (error) => {
  return {
    type: actionType.UPDATE_BUILDINGS_DATA_FAIL,
    error: error,
  };
};

export const updateBuildingsDataSuccess = (success) => {
  return {
    type: actionType.UPDATE_BUILDINGS_DATA_SUCCESS,
    success: success,
  };
};

export const updateBuildingsData = (data, building, toggle, setSubmitting) => {
  return (dispatch) => {
    dispatch(updateBuildingsDataStart());
    dispatch(buildingsUpdateLoading());

    axios
      .put(baseUrl + `/buildings/${data.id}`, building, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        dispatch(updateBuildingsDataSuccess(res.data));
        dispatch(buildingsGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(updateBuildingsDataFail(error));
      });
  };
};
