import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";

export default function LinerLoader() {
  return (
   
    <div style={{ width: "100%" }}>
      <LinearProgress />
    </div>

  );
}
