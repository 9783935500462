import React from "react";
import { Container, Card, CardBody, CardFooter, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { imageUrl } from "../../../shared/imageUrl";

const FeaturedProperties = () => {
  const properties = useSelector((state) => state.properties);
  console.log("props feature", properties?.properties);
  return (
    <React.Fragment>
      <Container>
        <hr />
        <h2 className="font-weight-bold text-center mt-5 text-blue">
          Hot Selling
        </h2>
      </Container>
      <Container className="mt-3">
        <Row className="">
          {properties?.properties?.length > 0 &&
            properties?.properties
              .filter(
                (item) => item.is_featured == "1" && item.approved_id !== null
              )
              ?.slice(0, 2)
              .map((home, index) => {
                // console.log("home feature", home.is_featured);
                // if (home.is_featured == 1)
                return (
                  <Col md={6} key={index}>
                    <Card className="list-cards">
                      <div className="row ">
                        <div class="col-md-5">
                          <img
                          src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                            // src={`http://www.omra.in/wp-data-1/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                            width="100%"
                            height="240px"
                            className="card-img"
                          />
                        </div>
                        <div class="col-md-7">
                          <div className="card-body">
                            <h5 class="card-title text-blue font-weight-bold">
                              Property For :
                              <span>{home.posting_property_for}</span>
                            </h5>
                            <ul className="list-group">
                              <li className="list-group-item">
                                <span className="font-weight-bold">
                                  Price :
                                </span>
                                &nbsp; &#8377; {home.property_price}
                              </li>
                              <li className="list-group-item">
                                <span className="font-weight-bold">
                                  Location :
                                </span>
                                &nbsp; {home.prop_city},{home.prop_area}
                              </li>
                            </ul>
                            <p class="card-text">
                              {/* {home.desccription.substr(0, 200)}..... */}
                              {home.desccription}
                              <br />
                              <Link
                                to={`/property/${home.id}`}
                                style={{
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                Read More
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FeaturedProperties;
