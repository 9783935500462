import * as actionType from "../action/ActionTypes";

const initialState = {
  buildings: [],
  postBuildings: [],
  error: false,
  isLoading: false,
  isPostLoading: false,
  isUpdateLoading: false,
};

export const Buildings = (state = initialState, action) => {
  switch (action.type) {
    case actionType.BUILDINGS_SET_DATA:
      return {
        ...state,
        buildings: action.buildings,
        error: false,
        isLoading: false,
        isPostLoading: false,
        isUpdateLoading: false,
      };

    case actionType.BUILDINGS_FAIL_DATA:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isPostLoading: false,
        isUpdateLoading: false,
      };
    case actionType.BUILDINGS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actionType.BUILDINGS_POST_LOADING:
      return {
        ...state,
        isPostLoading: true,
        error: false,
      };
    case actionType.BUILDINGS_UPDATE_LOADING:
      return {
        ...state,
        isUpdateLoading: true,
        error: false,
      };
    case actionType.POST_BUILDINGS_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isPostLoading: false,
        isUpdateLoading: false,
      };
    case actionType.POST_BUILDINGS_DATA_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        error: false,
        postBuildings: action.postBuildings,
      };
    case actionType.UPDATE_BUILDINGS_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isPostLoading: false,
        isUpdateLoading: false,
      };
    case actionType.UPDATE_BUILDINGS_DATA_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        error: false,
        isUpdateLoading: false,
      };
    case actionType.UPDATE_BUILDINGS_DATA_START:
      return {
        ...state,
      };

    default:
      return state;
  }
};
