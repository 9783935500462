import * as actionType from "./ActionTypes";
import axios from "../../axios";
import { baseUrl } from "../../shared/baseUrl";
import Swal from "sweetalert2";

export const propertiesSetData = (properties) => {
  return {
    type: actionType.PROPERTIES_SET_DATA,
    properties: properties,
  };
};

export const propertiesFailData = (error) => {
  return {
    type: actionType.PROPERTIES_FAIL_DATA,
    error: error,
  };
};

export const propertiesLoading = () => {
  return {
    type: actionType.PROPERTIES_LOADING,
  };
};
export const propertiesGetData = (data) => {
  return (dispatch) => {
    dispatch(propertiesLoading());
    axios
      .get(baseUrl + "/properties", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        console.log(res.data, "res");
        dispatch(propertiesSetData(res.data));
      })
      .catch((error) => dispatch(propertiesFailData(error)));
  };
};
export const deletePropertiesFail = (error) => {
  return {
    type: actionType.DELETE_PROPERTIES_FAIL,
    error: error,
  };
};

export const deleteProperties = (id, data) => {
  return (dispatch) => {
    if (id) {
      axios
        .delete(baseUrl + `/properties/${data.id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + data?.token,
          },
        })
        .then(() => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
            () => {
              dispatch(propertiesGetData(data));
            }
          );
        })
        .catch((error) => dispatch(deletePropertiesFail()));
    }
  };
};

export const postPropertiesDataStart = () => {
  return {
    type: actionType.POST_PROPERTIES_DATA_START,
  };
};

export const postPropertiesDataFail = (error) => {
  return {
    type: actionType.POST_PROPERTIES_DATA_FAIL,
    error: error,
  };
};

export const postPropertiesDataSuccess = (success) => {
  return {
    type: actionType.POST_PROPERTIES_DATA_SUCCESS,
    success: success,
  };
};

export const propertiesPostLoading = () => {
  return {
    type: actionType.PROPERTIES_POST_LOADING,
  };
};

export const postPropertiesData = (data, property, toggle, setSubmitting) => {
  return (dispatch) => {
    console.log("property", property);
    dispatch(postPropertiesDataStart());
    dispatch(propertiesPostLoading());
    axios
      .post(baseUrl + "/properties", property, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        dispatch(postPropertiesDataSuccess(res.data));
        dispatch(propertiesGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Created",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(postPropertiesDataFail(error));
      });
  };
};

// upload images
export const postPropertiesImageDataStart = () => {
  return {
    type: actionType.POST_PROPERTIES_IMAGES_DATA_START,
  };
};

export const postPropertiesImageDataFail = (error) => {
  return {
    type: actionType.POST_PROPERTIES_IMAGES_DATA_FAIL,
    error: error,
  };
};

export const postPropertiesImageDataSuccess = (success) => {
  return {
    type: actionType.POST_PROPERTIES_IMAGES_DATA_SUCCESS,
    success: success,
  };
};

export const propertiesImagePostLoading = () => {
  return {
    type: actionType.PROPERTIES_IMAGES_POST_LOADING,
  };
};

export const postPropertiesImages = (data, property, toggle, setSubmitting) => {
  return (dispatch) => {
    dispatch(postPropertiesImageDataStart());
    dispatch(propertiesImagePostLoading());
    axios
      .post(baseUrl + `/bulk-property-photos/${data.id}`, property, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        dispatch(postPropertiesImageDataSuccess(res.data));
        dispatch(propertiesGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Uploaded!", 
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(postPropertiesImageDataFail(error));
      });
  };
};

//
export const updatePropertiesDataStart = () => {
  return {
    type: actionType.UPDATE_PROPERTIES_DATA_START,
  };
};

export const propertiesUpdateLoading = () => {
  return {
    type: actionType.PROPERTIES_UPDATE_LOADING,
  };
};

export const updatePropertiesDataFail = (error) => {
  return {
    type: actionType.UPDATE_PROPERTIES_DATA_FAIL,
    error: error,
  };
};

export const updatePropertiesDataSuccess = (success) => {
  return {
    type: actionType.UPDATE_PROPERTIES_DATA_SUCCESS,
    success: success,
  };
};

export const updatePropertiesData = (data, property, toggle, setSubmitting) => {
  return (dispatch) => {
    dispatch(updatePropertiesDataStart());
    dispatch(propertiesUpdateLoading());

    axios
      .put(baseUrl + `/properties/${data.id}`, property, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
      .then((res) => {
        dispatch(updatePropertiesDataSuccess(res.data));
        dispatch(propertiesGetData(data));
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          if (toggle) {
            toggle();
          }
          if (setSubmitting) {
            setSubmitting(false);
          }
        });
      })
      .catch((error) => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        dispatch(updatePropertiesDataFail(error));
      });
  };
};
