import * as actionType from "../action/ActionTypes";

const initialState = {
  properties: [],
  postProperties: [],
  uploadImages: [],
  error: false,
  isLoading: false,
  isPostLoading: false,
  isUpdateLoading: false,
  isUploadLoading: false, 
};

export const Properties = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROPERTIES_SET_DATA:
      return {
        ...state,
        properties: action.properties,
        error: false,
        isLoading: false,
        isPostLoading: false,
        isUpdateLoading: false,
        isUploadLoading: false,
      };

    case actionType.PROPERTIES_FAIL_DATA:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isPostLoading: false,
        isUpdateLoading: false,
        isUploadLoading: false,
      };
    case actionType.PROPERTIES_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actionType.PROPERTIES_POST_LOADING:
      return {
        ...state,
        isPostLoading: true,
        error: false,
      };
    case actionType.PROPERTIES_UPDATE_LOADING:
      return {
        ...state,
        isUpdateLoading: true,
        error: false,
      };
    case actionType.POST_PROPERTIES_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isPostLoading: false,
        isUpdateLoading: false,
      };
    case actionType.POST_PROPERTIES_DATA_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        error: false,
        postProperties: action.postProperties,
      };
    //  post images
    case actionType.PROPERTIES_IMAGES_POST_LOADING:
      return {
        ...state,
        isUploadLoading: true,
        error: false,
      };
    case actionType.POST_PROPERTIES_IMAGES_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isPostLoading: false,
        isUpdateLoading: false,
        isUploadLoading: false,
      };
    case actionType.POST_PROPERTIES_IMAGES_DATA_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        error: false,
        uploadImages: action.uploadImages,
      };
    case actionType.UPDATE_PROPERTIES_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isPostLoading: false,
        isUpdateLoading: false,
      };
    case actionType.UPDATE_PROPERTIES_DATA_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        error: false,
        isUpdateLoading: false,
      };
    case actionType.UPDATE_PROPERTIES_DATA_START:
      return {
        ...state,
      };

    default:
      return state;
  }
};
