import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
} from "reactstrap";
import RentalCaurosel from "./RentalCaurosel";
import { connect } from "react-redux";
import * as actions from "../../../redux/action";
import Stack from "@mui/material/Stack";
import CircularProgres from "../../Loaders/CircularProgres";
import { propTypes } from "react-bootstrap/esm/Image";
import { imageUrl } from "../../../shared/imageUrl";

const List = (props) => {
  console.log("props List", props);
  const { id } = useParams();
  let data = {
    token: props.login?.login?.token,
    id: id,
  };

  useEffect(() => {
    props.onPropertiesGetData(data);
  }, []);
  const [visible, setVisible] = useState(3);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);
  };
  // const [properties, setProperties] = useState(props.properties?.properties);
  // console.log("first", properties);
  // const filterItem = (propType) => {
  //   const updateItems = props.properties?.properties.filter((curElm) => {
  //     return curElm.posting_property_for === propType;
  //   });
  //   setProperties(updateItems);
  // };

  return (
    <React.Fragment>
      <Container>
        <h2 className="font-weight-bold text-blue text-center mt-5">
          Our Listings Portfolio
        </h2>
        <p>
          These are the latest properties in the Sales category. You can create
          the list using the “latest listing shortcode” and show items by
          specific categories.
        </p>
      </Container>
      {/* <Container>
        <Row>
          <Col md={3}></Col>
          <Col md={6} className="text-center">
            <div>
              <button
                className="btn btn-sm bg-blue text-white"
                onClick={() => filterItem("Rent")}
              >
                Rental
              </button>
              <button
                className="btn btn-sm bg-blue text-white"
                // onClick={() => filterSell("Sell")}
                onClick={() => filterItem("Sell")}
              >
                Sales
              </button>
              <button
                className="btn btn-sm bg-blue text-white"
                onClick={() => filterItem("Invest")}
              >
                Invest
              </button>
            </div>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container> */}
      <Container>
        <Row className="mt-3">
          {props.properties?.properties?.length > 0 &&
            props.properties?.properties
              ?.filter((el) => el.approved_id !== null)
              ?.slice(0, visible)
              .map((home, index) => {
                // console.log("home11", home);
                //if (home.approved_id !== null)
                return (
                  <Col md={4} key={index}>
                    <Card className="list-cards">
                      <img
                        src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                        // src={`http://www.omra.in/wp-data-1/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                        width="100%"
                        height="200px"
                      />
                      <CardBody>
                        <ul className="list-group">
                          <li className="list-group-item ">
                            <span className="font-weight-bold">
                              Property For :
                            </span>
                            &nbsp;
                            {home.posting_property_for}
                          </li>
                          <li className="list-group-item ">
                            <span className="font-weight-bold">
                              Property Type :
                            </span>
                            &nbsp;
                            {home.property_type}
                          </li>
                          <li className="list-group-item">
                            <span className="font-weight-bold">Price :</span>
                            &nbsp; &#8377; {home.property_price}
                          </li>
                          <li className="list-group-item">
                            <span className="font-weight-bold">Location :</span>
                            &nbsp; {home.prop_city},{home.prop_area}
                          </li>
                        </ul>
                      </CardBody>
                      <CardFooter className="justify-content-center d-flex">
                        <Link
                          to={`/property/${home.id}`}
                          className="btn btn-blue btn-sm text-white"
                        >
                          Read More
                        </Link>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
        </Row>
        {/* <Row className="mt-3">
          {props.properties?.isLoading ? (
            <CircularProgres />
          ) : properties.length > 0 ? (
            properties?.slice(0, visible)?.map((home, index) => {
              if (home.approved_id !== null)
                return (
                  <Col md={4} key={index}>
                    <Card className="list-cards">
                      <img
                        src={`https://uditsolutions.in/TrueHomesBackend/storage/app/public/PropertyPhotos/${home.images[0]?.file_name}`}
                        width="100%"
                        height="200px"
                      />
                      <CardBody>
                        <ul className="list-group">
                          <li className="list-group-item ">
                            <span className="font-weight-bold">
                              Property For :
                            </span>
                            &nbsp;
                            {home.posting_property_for}
                          </li>
                          <li className="list-group-item ">
                            <span className="font-weight-bold">
                              Property Type :
                            </span>
                            &nbsp;
                            {home.property_type}
                          </li>
                          <li className="list-group-item">
                            <span className="font-weight-bold">Price :</span>
                            &nbsp; &#8377; {home.property_price}
                          </li>
                          <li className="list-group-item">
                            <span className="font-weight-bold">Location :</span>
                            &nbsp; {home.prop_city},{home.prop_area}
                          </li>
                        </ul>
                      </CardBody>
                      <CardFooter className="justify-content-center d-flex">
                        <Link
                          to={`/property/${home.id}`}
                          className="btn btn-blue btn-sm text-white"
                        >
                          Read More
                        </Link>
                      </CardFooter>
                    </Card>
                  </Col>
                );
            })
          ) : (
            ""
          )}
        </Row> */}
      </Container>
      <Container className="mt-5">
        <Row>
          <Button className="btn btn-primary load-btn" onClick={showMoreItems}>
            Load More Listings
          </Button>
        </Row>
      </Container>
      <hr />
      <Container>
        <h2 className="font-weight-bold text-blue text-center mt-5">
          Listings available for Rent
        </h2>
        <p>
          These are the latest properties in the Rentals category displayed
          using the latest properties slider shortcode. You control the number
          of listings.
        </p>
        <RentalCaurosel />
      </Container>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    properties: state.properties,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesGetData: (data) => dispatch(actions.propertiesGetData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(List);
