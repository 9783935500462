import * as actionType from "../action/ActionTypes";

const initialState = {
  singlebuildings: [],
  error: false,
  isLoading: false,
};

export const SingleBuildings = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SINGLE_BUILDINGS_SET_DATA:
      return {
        ...state,
        singlebuildings: action.singlebuildings,
        error: false,
        isLoading: false,
      };
    case actionType.SINGLE_BUILDINGS_FAIL_DATA:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case actionType.SINGLE_BUILDINGS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    default:
      return state;
  }
};
