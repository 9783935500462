import * as ActionTypes from "./ActionTypes";
import Swal from "sweetalert2";
import axios from "../../axios";

export const updateSetData = (update) => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_SET_DATA,
    update: update,
  };
};
export const updateFailData = (error) => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_FAIL_DATA,
    error: error,
  };
};
//loading
export const updateLoading = () => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_LOADING,
  };
};

//Add new code
export const updatePassword = (data, token, toggle) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    axios
      .post("/update-password", data, config)
      .then((res) => {
        Swal.fire({
          position: "success",
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(updateSetData(res.data));
        if (toggle) {
          toggle();
        }
      })
      .catch((error) => console.log("api error", error));
  };
};
