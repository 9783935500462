import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Row, Col, Label, InputGroup, Button } from "reactstrap";
import * as actions from "../../../redux/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";

const RentFilters = (props) => {
  console.log("props12", props); 

  const navigate = useNavigate();
  const optionList = [
    { id: 1, value: "one_RK", label: "1 RK" },
    { id: 2, value: "one_BHK", label: "1 BHK" },
    { id: 3, value: "two_BHK", label: "2 BHK" },
    { id: 4, value: "three_BHK", label: "3 BHK" },
    { id: 5, value: "four_BHK", label: "4 BHK" },
    // { id: 6, value: "five_BHK", label: "5 BHK" },
  ];

  //  demo code varcha
  const toggle = () => {
    navigate("/search-results");
  };
  let data = {
    token: props.login?.login?.token,
  };
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("values in filter properties:", values); 

    let property = {
      prop_city: values.prop_city,
      looking_for: values.looking_for,
      property_type: values.property_type,
      one_RK: values.rooms.find((room) => room.id == 1) ? 1 : 0,
      one_BHK: values.rooms.find((room) => room.id == 2) ? 1 : 0,
      two_BHK: values.rooms.find((room) => room.id == 3) ? 1 : 0,
      three_BHK: values.rooms.find((room) => room.id == 4) ? 1 : 0,
      four_BHK: values.rooms.find((room) => room.id == 5) ? 1 : 0,
    };
    console.log("Data of filter:", property);
    props.postFilterPropertiesData(data, property, toggle, setSubmitting);
    setSubmitting(true);
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          prop_city: "",
          looking_for: "",
          property_type: "",
          one_RK: "",
          one_BHK: "",
          two_BHK: "",
          three_BHK: "",
          four_BHK: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          property_type: Yup.string().required("Required"),
        })}
      >
        {(formProps) => (
          <Form>
            {console.log("formProps", formProps.values)}
            <Row className="form-group">
              <Col md={10}>
                <Row>
                  <Col md={4}>
                    <InputGroup>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="prop_city"
                        label="Location"
                        name="prop_city"
                        value={formProps.values.prop_city}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.prop_city &&
                          Boolean(formProps.errors.prop_city)
                        }
                        helperText={
                          formProps.touched.prop_city &&
                          formProps.errors.prop_city
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        size="small"
                        id="property_type"
                        label="Property Type *"
                        name="property_type"
                        value={formProps.values.property_type}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.property_type &&
                          Boolean(formProps.errors.property_type)
                        }
                        helperText={
                          formProps.touched.property_type &&
                          formProps.errors.property_type
                        }
                      >
                        <MenuItem value="">Select Property Type</MenuItem>
                        <MenuItem value="House">House</MenuItem>
                        <MenuItem value="Plot">Plot</MenuItem>
                      </TextField>
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="rooms"
                      size="small"
                      name="rooms"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      options={optionList}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        // console.log("event", event);
                        // console.log("newValue", newValue);
                        formProps.setFieldValue(`rooms`, newValue ?? "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          //variant="outlined"
                          name="rooms"
                          label="Select Rooms"
                          placeholder="Select"
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
                <Row>
                  <Col md={12}>
                    <Button
                      className="btn-search p-2"
                      type="submit"
                      disabled={formProps.isSubmitting}
                    >
                      <i
                        className="fa fa-search text-light"
                        aria-hidden="true"
                      />
                      &nbsp;Search
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    filterproperties: state.filterproperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postFilterPropertiesData: (data, property, toggle, setSubmitting) =>
      dispatch(
        actions.postFilterPropertiesData(data, property, toggle, setSubmitting)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RentFilters);
