import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import "./footer.css";
import ScrollToTop from "react-scroll-to-top";
import { useSelector } from "react-redux";
import { FaGooglePlay } from "react-icons/fa";
import { imageUrl } from "../../shared/imageUrl";

const Footer = () => {
  const properties = useSelector((state) => state.properties);
  console.log("properties footer", properties);
  return (
    <React.Fragment>
      <section className="footer-main">
        <ScrollToTop smooth />
        <Container>
          <Row>
            <Col md={5}>
              <h5 className="text-white">Contact Us</h5>
              <div className="social-sidebar">
                <p>
                  <i className="fa fa-building p-2" /> Shop No. 104, Hotcake
                  Imperia Tower, Vinaynagar Road, Mira-Bhayander 401107
                </p>
                <p>
                  <i className="fa fa-phone p-2" /> +91 84336 57812
                </p>
                <p>
                  <i className="fa fa-phone p-2" /> +91 97698 09512
                </p>
                <p>
                  <i className="fa fa-phone p-2" /> +91 78638 78696
                </p>
                <p>
                  <i className="fa fa-envelope p-2" /> support@truehomesindia.in
                </p>
                {/* <p>
                  <i className="fa fa-skype p-2" /> wpestatetheme
                </p>
                <p>
                  <i className="fa fa-desktop p-2" /> WP RESIDENCE
                </p> */}
              </div>
              <div className="row">
                <div className="social-footer col-md-12">
                  <a href="https://facebook.com/truehomesofficial">
                    <i className="fa fa-facebook fa-2x" />
                  </a>
                  <a href="https://instagram.com/truehomesofficial">
                    <i className="fa fa fa-instagram fa-2x" />
                  </a>
                  <a href="https://youtube.com/@truehomesofficial">
                    <i className="fa fa-youtube-play fa-2x" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.trueHome">
                    {/* <i class="fa fa-google-play"></i> */}
                    <i>
                      <FaGooglePlay />
                    </i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <h5 className="text-white">Lists by Category</h5>
              <div>
                <p>
                  <Link to="/all-flat" className="list-category">
                    Flat
                  </Link>
                </p>
                <p>
                  <Link to="/all-house" className="list-category">
                    House
                  </Link>
                </p>
                <p>
                  <Link to="/all-farm-house" className="list-category">
                    Farm House
                  </Link>
                </p>
                <p>
                  <Link to="/all-showroom" className="list-category">
                    Showroom
                  </Link>
                </p>
                <p>
                  <Link to="/all-plot" className="list-category">
                    Plot
                  </Link>
                </p>
                <p>
                  <Link to="/all-shop" className="list-category">
                    Shop
                  </Link>
                </p>
                <p>
                  <Link to="/office-space" className="list-category">
                    Office Space
                  </Link>
                </p>
                <p>
                  <Link to="/agricultural-land" className="list-category">
                    Agricultural Land
                  </Link>
                </p>
                <p>
                  <Link to="/property-listing" className="list-category">
                    Other
                  </Link>
                </p>
              </div>
            </Col>
            <Col md={4}>
              <h5 className="text-white">Latest Properties</h5>

              <Row className="">
                {properties?.properties?.length > 0 &&
                  properties?.properties
                    .filter(
                      (item) =>
                        item.is_featured == "1" && item.approved_id !== null
                    )
                    ?.slice(0, 3)
                    .map((home, index) => {
                      return (
                        <Col md={12} key={index}>
                          <Card className="list-cards">
                            <div className="row ">
                              <div
                                class="col-md-6 footer-img"
                                style={{ paddingRight: "0px" }}
                              >
                                <Link
                                  to={`/property/${home.id}`}
                                  style={{
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <img
                                    src={`${imageUrl}/PropertyPhotos/${home.images[0]?.file_name}`}
                                    width="100%"
                                    height="110px"
                                    className="card-img"
                                  />
                                </Link>
                              </div>
                              <div
                                class="col-md-6 footer-content"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#002547",
                                    width: "100%",
                                    height: "100%",
                                    color: "#fff",
                                  }}
                                >
                                  <p
                                    className="text-white"
                                    style={{ margin: "0" }}
                                  >
                                    For :{home.posting_property_for}
                                  </p>
                                  <p
                                    className="text-white"
                                    style={{ margin: "0" }}
                                  >
                                    from :&nbsp; &#8377; {home.property_price}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <Col md={6}>
              <p style={{ color: "#ccc" }}>
                Copyright True Homes. All Rights Reserved
              </p>
            </Col>
            <Col md={6}>
              <div className="footer-links">
                <ul>
                  <li>Documentation</li>
                  <li>Video Tutorial </li>
                  <li>Client Support</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Footer;
