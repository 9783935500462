import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../custom/CustomSelect";
import CustomInput from "../../custom/CustomInput";
import { Row, Col, Label, InputGroup, Button } from "reactstrap";
import * as actions from "../../../redux/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";

const CommertialFilters = (props) => {
  console.log("props rent filter", props);
  const navigate = useNavigate();
  const toggle = () => {
    navigate("/search-commertial");
  };
  let data = {
    token: props.login?.login?.token,
  };
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("values in filter properties:", values);

    let property = {
      prop_city: values.prop_city,
      looking_for: values.looking_for,
      property_type: values.property_type,
    };
    console.log("Data of filter:", property);
    props.postcFilterData(data, property, toggle, setSubmitting);
    setSubmitting(true);
    return;
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          prop_city: "",
          looking_for: "",
          property_type: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          property_type: Yup.string().required("Required"),
        })}
      >
        {(formProps) => (
          <Form>
            {console.log("formProps", formProps.values)}
            <Row className="form-group">
              <Col md={10}>
                <Row>
                  <Col md={4}>
                    <InputGroup>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="prop_city"
                        label="Location"
                        name="prop_city"
                        value={formProps.values.prop_city}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.prop_city &&
                          Boolean(formProps.errors.prop_city)
                        }
                        helperText={
                          formProps.touched.prop_city &&
                          formProps.errors.prop_city
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        size="small"
                        id="looking_for"
                        label="Property For"
                        name="looking_for"
                        value={formProps.values.looking_for}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.looking_for &&
                          Boolean(formProps.errors.looking_for)
                        }
                        helperText={
                          formProps.touched.looking_for &&
                          formProps.errors.looking_for
                        }
                      >
                        <MenuItem value="">Select Property For</MenuItem>
                        <MenuItem value="Buy">Buy</MenuItem>
                        <MenuItem value="Rent">Rent</MenuItem>
                      </TextField>
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        size="small"
                        id="property_type"
                        label="Property Type"
                        name="property_type"
                        value={formProps.values.property_type}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.property_type &&
                          Boolean(formProps.errors.property_type)
                        }
                        helperText={
                          formProps.touched.property_type &&
                          formProps.errors.property_type
                        }
                      >
                        <MenuItem value="">Select Property For</MenuItem>
                        <MenuItem value="Shop">Shop</MenuItem>
                        <MenuItem value="Showroom">Showroom</MenuItem>
                        <MenuItem value="Office Space">Office Space</MenuItem>
                        <MenuItem value="Other Commercials">
                          Other Commercials
                        </MenuItem>
                      </TextField>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
                <Row>
                  <Col md={12}>
                    <Button
                      className="btn-search p-2"
                      type="submit"
                      disabled={formProps.isSubmitting}
                    >
                      <i
                        className="fa fa-search text-light"
                        aria-hidden="true"
                      />
                      &nbsp;Search
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    cfilter: state.cfilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postcFilterData: (data, property, toggle, setSubmitting) =>
      dispatch(actions.postcFilterData(data, property, toggle, setSubmitting)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommertialFilters);
