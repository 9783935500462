import * as actionType from "../action/ActionTypes";

const initialState = {
  cfilter: [],
  error: false,
  isLoading: false,
};

export const CommertialFilter = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FILTER_COMMERTIAL_PROPERTIES_SET_DATA:
      return {
        ...state,
        cfilter: action.cfilter,
        error: false,
        isLoading: false,
      };

    case actionType.FILTER_COMMERTIAL_PROPERTIES_FAIL_DATA:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case actionType.FILTER_COMMERTIAL_PROPERTIES_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    default:
      return state;
  }
};
