import React from "react";
import { Container } from "reactstrap";
import team1 from "../../../assets/images/team2.jpeg";
import team2 from "../../../assets/images/agent1.png";
import team3 from "../../../assets/images/agent2.jpg";


const Testimonials = () => {
  return (
    <React.Fragment>
      <section id="main-section" className="mt-5">
        <Container>
          <h2 className="font-weight-bold text-center mt-5 text-blue">
            Testimonials
          </h2>
          <p className="text-center">
            Publish the best of your client testimonials and let the world know
            what a great agent or real estate agency you are. Testimonials build
            trust.
          </p>
        </Container>
        <Container className="mt-5">
          <div className="team">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="team">
                    <img
                      src={team1}
                      className="rounded-circle"
                      width="40px"
                      height="40px"
                    />
                    <h4>Sheema Gayakwad</h4>
                    {/* <p className="team-des">developer</p> */}
                  </div>
                  <div>
                    <p className="team-info">
                      Sachin helped me getting a good flat in a good location as
                      per my conditions. He answered all my questions patiently
                      and was available for resolving all doubts. He was
                      thoroughly professional throughout our interaction.
                      Truehomes seemed to have good options at good prices.
                    </p>
                  </div>
                  <div className="rating-icons">
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="team">
                    <img
                      src={team2}
                      className="rounded-circle"
                      width="40px"
                      height="40px"
                    />
                    <h4>Falak Shaikh</h4>
                    {/* <p className="team-des">happy seller</p> */}
                  </div>
                  <div>
                    <p className="team-info">
                      We've been on the lookout for a new home for the past 5
                      months, we came across countless real-estate agents and
                      agency, no one could really help us find that one home we
                      were looking for. It was a chance encounter with Truehomes
                      and specifically Ahmed, that helped us find the home we
                      were looking for.
                      {/* Of all the agents/agency that we came
                      across, they have been the most patient and committed to
                      help us find a solution. These guys won't disappoint, be
                      rest assured of that. */}
                    </p>
                  </div>
                  <div className="rating-icons">
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="team">
                    <img
                      src={team3}
                      className="rounded-circle"
                      width="40px"
                      height="40px"
                    />
                    <h4>Ahsaan Malik</h4>
                    {/* <p className="team-des">happy buyer</p> */}
                  </div>
                  <div>
                    <p className="team-info">
                      I came in contact with Mr. Hussain during our search for
                      buying a new home. I found him humble, professional & very
                      helpful. He listens to the client patiently & solve their
                      queries. Another quality that he has that I personally
                      like is, he always answer the call. Thank you
                    </p>
                  </div>
                  <div className="rating-icons">
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                    <i className="fa fa-star" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Testimonials;
