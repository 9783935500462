//LOGIN
export const ADD_LOGIN = "ADD_LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const REMOVE_LOGIN = "REMOVE_LOGIN";

//Sign up
export const ADD_REGISTER = "ADD_REGISTER";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_FAILED = "REGISTER_FAILED";

// export const PROPERTIES_SET_DATA = "PROPERTIES_SET_DATA";
// export const PROPERTIES_FAIL_DATA = "PROPERTIES_FAIL_DATA";
// export const PROPERTIES_LOADING = "PROPERTIES_LOADING";

// forgot password
export const FORGOT_PASSWORD_SET_DATA = "FORGOT_PASSWORD_SET_DATA";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_FAIL_DATA = "FORGOT_PASSWORD_FAIL_DATA";

// update password
export const UPDATE_PASSWORD_SET_DATA = "UPDATE_PASSWORD_SET_DATA";
export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";
export const UPDATE_PASSWORD_FAIL_DATA = "UPDATE_PASSWORD_FAIL_DATA";

//-----properties
export const PROPERTIES_GET_DATA = "PROPERTIES_GET_DATA";
export const PROPERTIES_SET_DATA = "PROPERTIES_SET_DATA";
export const PROPERTIES_FAIL_DATA = "PROPERTIES_FAIL_DATA";
export const DELETE_PROPERTIES_FAIL = "DELETE_PROPERTIES_FAIL";
export const POST_PROPERTIES_DATA = "POST_PROPERTIES_DATA";
export const POST_PROPERTIES_DATA_START = "POST_PROPERTIES_DATA_START";
export const POST_PROPERTIES_DATA_FAIL = "POST_PROPERTIES_DATA_FAIL";
export const POST_PROPERTIES_DATA_SUCCESS = "POST_PROPERTIES_DATA_SUCCESS";
export const EDIT_PROPERTIES_ROW_START = "EDIT_PROPERTIES_ROW_START";
export const FAIL_EDIT_PROPERTIES = "FAIL_EDIT_PROPERTIES";
export const UPDATE_PROPERTIES_DATA_START = "UPDATE_PROPERTIES_DATA_START";
export const UPDATE_PROPERTIES_DATA_FAIL = "UPDATE_PROPERTIES_DATA_FAIL";
export const UPDATE_PROPERTIES_DATA_SUCCESS = "UPDATE_PROPERTIES_DATA_SUCCESS";
export const PROPERTIES_LOADING = "PROPERTIES_LOADING";
export const PROPERTIES_POST_LOADING = "PROPERTIES_POST_LOADING";
export const PROPERTIES_UPDATE_LOADING = "PROPERTIES_UPDATE_LOADING";
// images properties
export const PROPERTIES_IMAGES_POST_LOADING = "PROPERTIES_POST_LOADING";
export const POST_PROPERTIES_IMAGES_DATA = "POST_PROPERTIES_IMAGES_DATA";
export const POST_PROPERTIES_IMAGES_DATA_START =
  "POST_PROPERTIES_IMAGES_DATA_START";
export const POST_PROPERTIES_IMAGES_DATA_FAIL =
  "POST_PROPERTIES_IMAGES_DATA_FAIL";
export const POST_PROPERTIES_IMAGES_DATA_SUCCESS =
  "POST_PROPERTIES_IMAGES_DATA_SUCCESS";

// single properties SINGLE_
export const SINGLE_PROPERTIES_LOADING = "SINGLE_PROPERTIES_LOADING";
export const SINGLE_PROPERTIES_GET_DATA = "SINGLE_PROPERTIES_GET_DATA";
export const SINGLE_PROPERTIES_SET_DATA = "SINGLE_PROPERTIES_SET_DATA";
export const SINGLE_PROPERTIES_FAIL_DATA = "SINGLE_PROPERTIES_FAIL_DATA";

// buildings
export const BUILDINGS_GET_DATA = "BUILDINGS_GET_DATA";
export const BUILDINGS_SET_DATA = "BUILDINGS_SET_DATA";
export const BUILDINGS_FAIL_DATA = "BUILDINGS_FAIL_DATA";
export const DELETE_BUILDINGS_FAIL = "DELETE_BUILDINGS_FAIL";
export const POST_BUILDINGS_DATA = "POST_BUILDINGS_DATA";
export const POST_BUILDINGS_DATA_START = "POST_BUILDINGS_DATA_START";
export const POST_BUILDINGS_DATA_FAIL = "POST_BUILDINGS_DATA_FAIL";
export const POST_BUILDINGS_DATA_SUCCESS = "POST_BUILDINGS_DATA_SUCCESS";
export const EDIT_BUILDINGS_ROW_START = "EDIT_BUILDINGS_ROW_START";
export const FAIL_EDIT_BUILDINGS = "FAIL_EDIT_BUILDINGS";
export const UPDATE_BUILDINGS_DATA_START = "UPDATE_BUILDINGS_DATA_START";
export const UPDATE_BUILDINGS_DATA_FAIL = "UPDATE_BUILDINGS_DATA_FAIL";
export const UPDATE_BUILDINGS_DATA_SUCCESS = "UPDATE_BUILDINGS_DATA_SUCCESS";
export const BUILDINGS_LOADING = "BUILDINGS_LOADING";
export const BUILDINGS_POST_LOADING = "BUILDINGS_POST_LOADING";
export const BUILDINGS_UPDATE_LOADING = "BUILDINGS_UPDATE_LOADING";

// amenities
export const AMENITIES_GET_DATA = "AMENITIES_GET_DATA";
export const AMENITIES_SET_DATA = "AMENITIES_SET_DATA";
export const AMENITIES_FAIL_DATA = "AMENITIES_FAIL_DATA";
export const DELETE_AMENITIES_FAIL = "DELETE_AMENITIES_FAIL";
export const POST_AMENITIES_DATA = "POST_AMENITIES_DATA";
export const POST_AMENITIES_DATA_START = "POST_AMENITIES_DATA_START";
export const POST_AMENITIES_DATA_FAIL = "POST_AMENITIES_DATA_FAIL";
export const POST_AMENITIES_DATA_SUCCESS = "POST_AMENITIES_DATA_SUCCESS";
export const UPDATE_AMENITIES_DATA_START = "UPDATE_AMENITIES_DATA_START";
export const UPDATE_AMENITIES_DATA_FAIL = "UPDATE_AMENITIES_DATA_FAIL";
export const UPDATE_AMENITIES_DATA_SUCCESS = "UPDATE_AMENITIES_DATA_SUCCESS";
export const AMENITIES_LOADING = "AMENITIES_LOADING";
export const AMENITIES_POST_LOADING = "AMENITIES_POST_LOADING";
export const AMENITIES_UPDATE_LOADING = "AMENITIES_UPDATE_LOADING";

// upload images
// export const UPLOAD_PROPERTIES_IMAGES_LOADING = "UPLOAD_PROPERTIES_IMAGES_LOADING";
// export const UPLOAD_PROPERTIES_IMAGES_GET_DATA = "UPLOAD_PROPERTIES_IMAGES_GET_DATA";
// export const UPLOAD_PROPERTIES_IMAGES_SET_DATA = "UPLOAD_PROPERTIES_IMAGES_SET_DATA";

// get single project
export const SINGLE_BUILDINGS_LOADING = "SINGLE_BUILDINGS_LOADING";
export const SINGLE_BUILDINGS_GET_DATA = "SINGLE_BUILDINGS_GET_DATA";
export const SINGLE_BUILDINGS_SET_DATA = "SINGLE_BUILDINGS_SET_DATA";
export const SINGLE_BUILDINGS_FAIL_DATA = "SINGLE_BUILDINGS_FAIL_DATA";

// filter buy aur rent properties
export const FILTER_PROPERTIES_LOADING = "FILTER_PROPERTIES_LOADING";
export const FILTER_PROPERTIES_GET_DATA = "FILTER_PROPERTIES_GET_DATA";
export const FILTER_PROPERTIES_SET_DATA = "FILTER_PROPERTIES_SET_DATA";
export const FILTER_PROPERTIES_FAIL_DATA = "FILTER_PROPERTIES_FAIL_DATA";

export const FILTER_COMMERTIAL_PROPERTIES_LOADING =
  "FILTER_COMMERTIAL_PROPERTIES_LOADING";
export const FILTER_COMMERTIAL_PROPERTIES_GET_DATA =
  "FILTER_COMMERTIAL_PROPERTIES_GET_DATA";
export const FILTER_COMMERTIAL_PROPERTIES_SET_DATA =
  "FILTER_COMMERTIAL_PROPERTIES_SET_DATA";
export const FILTER_COMMERTIAL_PROPERTIES_FAIL_DATA =
  "FILTER_COMMERTIAL_PROPERTIES_FAIL_DATA";
