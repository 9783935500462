import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomInput from "../../custom/CustomInput";
import { InputGroup, Button, Label, Row, Col, Container } from "reactstrap";

const Contact = () => {
  return (
    <React.Fragment>
      <section className="contact-main">
        <Container className="mt-5">
          <h3 className="text-white text-center">
            Contact us today if you’d like to know more <br />
            about how we help buy, sell or rent your home
          </h3>
        </Container>
        <Container className="mt-3">
          <div
            style={{
              // width: "80vw",
              // height: "80vh",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "50px",
            }}
          >
            <div className="auth-wrapper align-items-center">
              <div className="container" style={{ paddingTop: 35 }}>
                <div className="no-gutters justify-content-center row">
                  <div className="bg-white col-md-10 col-lg-10 contact-form">
                    <div className="p-4">
                      <h5 className="font-medium mb-4 font-weight-bold text-center">
                        Schedule a meeting with our team
                      </h5>
                      <p className="text-center">
                        Our experts and developers would love to <br />{" "}
                        contribute their expertise and insights
                      </p>
                      <Formik
                        initialValues={{
                          name: "",
                          mobile: "",
                          email: "",
                          city: "",
                          message: "",
                        }}
                        //onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                          // name: Yup.string().required("This field is required"),
                        })}
                      >
                        {(formProps) => (
                          <Form className="mt-3">
                            <Row className="form-group">
                              <Col md={6} className="mt-2">
                                <InputGroup>
                                  <Field
                                    component={CustomInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                    className={
                                      "form-control" +
                                      (formProps.errors.name &&
                                      formProps.touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Name"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </InputGroup>
                              </Col>
                              <Col md={6} className="mt-2">
                                <InputGroup>
                                  <Field
                                    component={CustomInput}
                                    type="text"
                                    name="mobile"
                                    id="mobile"
                                    className={"form-control"}
                                    placeholder="Mobile"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="form-group">
                              <Col md={6} className="mt-2">
                                <InputGroup>
                                  <Field
                                    component={CustomInput}
                                    type="text"
                                    name="email"
                                    id="email"
                                    className={"form-control"}
                                    placeholder="Email"
                                  />
                                </InputGroup>
                              </Col>
                              <Col md={6} className="mt-2">
                                <InputGroup>
                                  <Field
                                    component={CustomInput}
                                    type="text"
                                    name="city"
                                    id="city"
                                    className={"form-control"}
                                    placeholder="City"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="form-group">
                              <Col md={12}>
                                <InputGroup>
                                  <Field
                                    component={CustomInput}
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    className={"form-control"}
                                    placeholder="Message"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: "center" }}>
                              <Col md={12}>
                                <Button
                                  type="submit"
                                  disabled={formProps.isSubmitting}
                                  className="btn-contact"
                                  block
                                >
                                  Send Email
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
